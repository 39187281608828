import { Routes } from '@angular/router';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { TemplateBuilderComponent } from './features/template-builder-tool/template-builder.component';
import { TestTemplateComponent } from './features/template-builder-tool/components/test-template/test-template.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { SignInComponent } from './core/sign-in/sign-in.component';
import { VerifyTokenComponent } from './core/verify-token/verify-token.component';
import { DesignsComponent } from './features/dashboard/components/designs/designs.component';
import { TemplatesComponent } from './features/dashboard/components/templates/templates.component';
import { SignOutComponent } from './core/sign-out/sign-out.component';
import { StandardiseDesignsComponent } from './features/dashboard/components/standardise-designs/standardise-designs.component';
import { PersonalizedResourceComponent } from './features/template-builder-tool/components/personalized-resource/personalized-resource.component';
import { ConfigListComponent } from './features/template-builder-tool/components/config/config-list/config-list.component';

export const routes: Routes = [
  { path: '', component: SignInComponent, },
  { path: 'sign-in', component: SignInComponent },
  { path: 'verify-token', component: VerifyTokenComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],

    children: [
      { path: '', redirectTo: 'templates', pathMatch: 'full' },
      { path: 'designs', component: DesignsComponent },
      { path: 'standardize-designs/:job_code', component: StandardiseDesignsComponent },
      { path: 'templates', component: TemplatesComponent },
    ],
  },
  {
    path: 'template-builder/:templateId/:jobCode',
    canActivate: [AuthGuard],
    component: TemplateBuilderComponent,
    children: [
      { path: 'config-list', component: ConfigListComponent },
    ]
  },
  {
    path: 'config-list',
    component: ConfigListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'test-template',
    canActivate: [AuthGuard],
    component: TestTemplateComponent,
  },
  {
    path: 'personalized-resource/:templateId/:jobCode',
    component: PersonalizedResourceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'personalized-resource',
    component: PersonalizedResourceComponent,
    canActivate: [AuthGuard]
  },  
  {
    path: 'sign-out',
    component: SignOutComponent
  },
  { path: '**', redirectTo: '' },
];
