<div class="app-container">
  <!-- <app-nav-bar></app-nav-bar> -->
   <app-header></app-header>
  @if (masterTemplateJson) {
  @if(templateData?.template_type?.toLowerCase() == 'picklist'){
  <div class="main-content">
   <div class="picklist_message"> Canvas configuration and module mapping is not needed for picklist template type.<br>
    Please create the dropdown module configuration with the required screens as options and submit for approval.</div>
  </div>
  }
  @else{
    <div class="main-content">
      <app-side-bar [templateLayers]="images" [jobCode]="jobCode"></app-side-bar>
      <app-spinner></app-spinner>
      <app-editor [masterTemplate]="masterTemplateJson" [templateData]="templateData"></app-editor>
      <app-properties></app-properties>
    </div>
  }
  }
</div>
<app-footer></app-footer>