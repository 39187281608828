<div class="mainDiv">
    <h6>Module configuration for {{templateName}}({{templateId}})</h6>
    <app-spinner></app-spinner>
    <div class="button-group">
        <button mat-raised-button (click)="back()" class="button back-button">
            Back
        </button>
        <button mat-raised-button (click)="addConfig()" class="button config-button">
            Add Config
        </button>
    </div>
    <div class="tableDiv">
        <table mat-table [dataSource]="configData" class="mat-elevation-z8">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Module Id </th>
                <td mat-cell *matCellDef="let element"> {{element?.id}} </td>
            </ng-container>
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef> Label </th>
                <td mat-cell *matCellDef="let element"> {{element?.label}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> {{element?.type}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element" class="action-link">
                    <mat-icon aria-label="Edit" (click)="editConfig(element)">edit</mat-icon>
                </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    No records found
                </td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>