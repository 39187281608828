import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, catchError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { loadSourceTree, loadSourceTreeFailure, loadSourceTreeSuccess, loadTargetTree, loadTargetTreeFailure, loadTargetTreeSuccess, removeTargetElement } from '../actions/standardise-design.actions';
import { DesignsService } from '@app/features/dashboard/services/designs.service';
import { FileService } from '@app/features/dashboard/services/file.service';
import { endpoints } from '@app/shared/constants/auth.constants';
import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class StandardDesignEffect {
  private readonly actions$ = inject(Actions);
  private readonly authService = inject(AuthService);
  
  loadSourceTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSourceTree),
      switchMap((action) =>
        this.http.get(this.authService.getAWSUrl() + endpoints.listFiles, {params: {folderPath: `${action.folder}/source`}, 
          headers: {Authorization: localStorage.getItem('idp_access_token') ?? '', idp: localStorage.getItem('idp') ?? 'Cognito'}
        }).pipe(
          map(data => loadSourceTreeSuccess({ payload: data })),
          catchError(error => of(loadSourceTreeFailure({ payload: error })))
        )
      )
    )
  );

  loadTargetTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTargetTree),
      switchMap((action) =>
        this.designService.getDesign(action.folder).pipe(
          map(data => loadTargetTreeSuccess({ payload: data })),
          catchError(error => of(loadTargetTreeFailure({ payload: error })))
        )
      )
    )
  );

  deleteNode$ = createEffect(() => 
    this.actions$.pipe(
      ofType(removeTargetElement),
      map((action) => 
        action.node.link&& this.fileService.delete(action.node.link)
      )
    ),
    { dispatch: false }
  )

  constructor(private http: HttpClient, private designService: DesignsService, private fileService: FileService) {}
}