<div class="main-container">
  @if (isAccountExecutive || isCustomer) {
    <div class="notification-bar">
      <p class="notification-text">
        <span>{{
          isAccountExecutive
            ? labels.notificationBar.AE
            : labels.notificationBar.Customer
        }}</span>
      </p>
    </div>
  }
  
    <div class="navbar">
      <span class="navbar-label">{{ templateData?.template_name }}</span>
      <app-spinner></app-spinner>
      
  
    <div class="navbar-buttons">
      @if (isApprover) {
        <button class="navbar-button approved-button" (click)="doApproved()">
          Approve
        </button>
      }
      @if (isApprover) {
        <button class="navbar-button rejected-button" (click)="doRejected()">
          Reject
        </button>
      }
      @if (isAccountExecutive || isCustomer) {
        <button
          class="navbar-button save-button"
          [disabled]="!canSaveState"
          (click)="doFinish()">
          Finish
        </button>
      }
      @if (isAccountExecutive || isCustomer) {
        <button class="navbar-button save-draft-button" (click)="doSaveAsDraft()">
          Save as draft
        </button>
      }
      @if (isAccountExecutive || isCustomer) {
        <button class="navbar-button cancel-button" (click)="doCancel()">
          Cancel
        </button>
      }
      @if (isApprover || isAuthor) {
        <button class="navbar-button preview-button" (click)="doPreview()">
          Preview
        </button>
        <button class="navbar-button close-button" (click)="doClose()">
          Close
        </button>
        <!-- <button class="navbar-button preview-button" (click)="saveAsPDF()">
          pdf
        </button> -->
      }
    </div> 
  </div>
  
  <div class="personalized-resource-container">
    <div class="content-container">
      <div class="canvas">
        <div id="canvas-container" class="canvas-container">
          <canvas
            #canvas
            id="customizable-canvas"
            (mouseup)="onMouseUp($event)"
            [width]="canvasProperties.canvasWidth"
            [height]="canvasProperties.canvasHeight"></canvas>
        </div>
         <!-- @if (canvasPages.length > 1) { -->
         <div class="canvas-controls">
          <div class="pagination">
            <div class="previous">
              <button
                class="previous-button"
                (click)="previousPage()"
                [disabled]="isFirstPage()"
                [disabled]="currentPageIndex === 0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M1.33398 7.99936C1.33399 7.83201 1.39565 7.67907 1.49749 7.562C1.51587 7.54086 1.53567 7.52078 1.55682 7.50191C1.55893 7.50002 1.56107 7.49814 1.56321 7.49628L6.89498 2.83097C7.17207 2.58852 7.59325 2.6166 7.8357 2.89369C8.07816 3.17078 8.05008 3.59196 7.77299 3.83441L3.77494 7.3327H14.0007C14.3688 7.3327 14.6673 7.63118 14.6673 7.99937C14.6673 8.36756 14.3688 8.66603 14.0007 8.66603L3.77496 8.66603L7.77299 12.1643C8.05008 12.4068 8.07816 12.8279 7.8357 13.105C7.59325 13.3821 7.17207 13.4102 6.89498 13.1677L1.56534 8.5043C1.42366 8.38205 1.33398 8.20117 1.33398 7.99936C1.33398 7.99936 1.33398 7.99936 1.33398 7.99936Z"
                    fill="#00857C" />
                </svg>
                Previous
              </button>
            </div>
            <div class="page-buttons">
              <ng-container *ngIf="currentPageIndex > 1">
                <button class="page-number" (click)="selectPage(0)">1</button>
                <span *ngIf="currentPageIndex > 2">
                  <button class="page-number" disabled>...</button>
                </span>
              </ng-container>
  
              <ng-container *ngFor="let i of getVisiblePages()">
                <button
                  class="page-number"
                  [ngClass]="{ active: currentPageIndex === i }"
                  (click)="selectPage(i)">
                  {{ i + 1 }}
                </button>
              </ng-container>
  
              <ng-container *ngIf="currentPageIndex < canvasPages.length - 2">
                <span *ngIf="currentPageIndex < canvasPages.length - 3">
                  <button class="page-number" disabled>...</button>
                </span>
                <button
                  class="page-number"
                  (click)="selectPage(canvasPages.length - 1)">
                  {{ canvasPages.length }}
                </button>
              </ng-container>
            </div>
            <div class="next">
              <button
                class="next-button"
                (click)="nextPage()"
                [disabled]="isLastPage()"
                [disabled]="currentPageIndex === canvasPages.length - 1">
                Next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M14.6663 7.99936C14.6663 8.16671 14.6047 8.31965 14.5028 8.43671C14.4845 8.45786 14.4647 8.47794 14.4435 8.49681C14.4414 8.4987 14.4393 8.50058 14.4371 8.50244L9.10534 13.1677C8.82825 13.4102 8.40708 13.3821 8.16462 13.105C7.92217 12.8279 7.95025 12.4068 8.22734 12.1643L12.2254 8.66602L1.99967 8.66602C1.63148 8.66602 1.33301 8.36754 1.33301 7.99935C1.33301 7.63116 1.63148 7.33268 1.99967 7.33268L12.2254 7.33268L8.22734 3.83441C7.95025 3.59195 7.92217 3.17078 8.16462 2.89369C8.40708 2.6166 8.82825 2.58852 9.10534 2.83097L14.435 7.49441C14.5767 7.61667 14.6663 7.79754 14.6663 7.99936C14.6663 7.99935 14.6663 7.99936 14.6663 7.99936Z"
                    fill="#00857C" />
                </svg>
              </button>
            </div>
          </div>
        </div>
  
        <!-- } -->
      </div>
      <div class="action-buttons">
        @if (this.templateData?.template_type?.toLowerCase() !== 'picklist' && false) {
        <button class="action-button" (click)="undo()">
          <svg
            class="tools"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M11.9859 2.90626C6.80859 2.91329 2.625 7.10157 2.625 12.2789C2.625 15.2789 4.03594 17.9508 6.22969 19.6664L5.35078 20.7914C5.25469 20.9156 5.34375 21.0961 5.49844 21.0938L9.4125 21.075C9.53438 21.075 9.62344 20.9602 9.59297 20.843L8.66719 17.0391C8.65883 17.0048 8.64096 16.9735 8.61564 16.949C8.59031 16.9244 8.55857 16.9074 8.52404 16.9001C8.48952 16.8927 8.45363 16.8953 8.42049 16.9074C8.38735 16.9196 8.35831 16.9408 8.33672 16.9688L7.38281 18.1899C7.14375 18.0024 6.91406 17.7984 6.69609 17.5805C6.01083 16.8976 5.46477 16.0881 5.08828 15.1969C4.69688 14.2734 4.5 13.2914 4.5 12.2789C4.5 11.2664 4.69688 10.2844 5.08828 9.36095C5.46563 8.46798 6.00703 7.66642 6.69609 6.97735C7.38516 6.28829 8.18672 5.74688 9.07969 5.36954C10.0055 4.97813 10.9875 4.78126 12 4.78126C13.0125 4.78126 13.9945 4.97813 14.918 5.36954C15.8109 5.74688 16.6125 6.28829 17.3016 6.97735C17.9906 7.66642 18.532 8.46798 18.9094 9.36095C19.3008 10.2844 19.4977 11.2664 19.4977 12.2789C19.4977 13.2914 19.3008 14.2734 18.9094 15.1969C18.5329 16.0881 17.9868 16.8976 17.3016 17.5805C17.1258 17.7563 16.943 17.9203 16.7531 18.0774C16.7151 18.1082 16.6908 18.1529 16.6855 18.2016C16.6803 18.2504 16.6944 18.2992 16.725 18.3375L17.6484 19.5211C17.7141 19.6031 17.8336 19.6172 17.9156 19.5516C20.0273 17.8313 21.375 15.2133 21.375 12.2789C21.375 7.09688 17.1703 2.89923 11.9859 2.90626Z"
              fill="#556584" />
          </svg>
        </button>
  
        <button class="action-button" (click)="redo()">
          <svg
            class="tools"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M17.7703 19.6664C19.9641 17.9508 21.375 15.2789 21.375 12.2789C21.375 7.10157 17.1914 2.91329 12.0141 2.90626C6.82969 2.89923 2.625 7.09688 2.625 12.2789C2.625 15.2133 3.97266 17.8313 6.08438 19.5492C6.16641 19.6149 6.28594 19.6008 6.35156 19.5188L7.275 18.3352C7.33828 18.2555 7.32422 18.1406 7.24688 18.075C7.05703 17.9203 6.87422 17.7539 6.69844 17.5781C6.01317 16.8952 5.46712 16.0857 5.09062 15.1945C4.69687 14.2734 4.5 13.2914 4.5 12.2789C4.5 11.2664 4.69688 10.2844 5.08828 9.36095C5.46563 8.46798 6.00703 7.66642 6.69609 6.97735C7.38516 6.28829 8.18672 5.74688 9.07969 5.36954C10.0055 4.97813 10.9875 4.78126 12 4.78126C13.0125 4.78126 13.9945 4.97813 14.918 5.36954C15.8109 5.74688 16.6125 6.28829 17.3016 6.97735C17.9906 7.66642 18.532 8.46798 18.9094 9.36095C19.3008 10.2844 19.4977 11.2664 19.4977 12.2789C19.4977 13.2914 19.3008 14.2734 18.9094 15.1969C18.5329 16.0881 17.9868 16.8976 17.3016 17.5805C17.0836 17.7984 16.8539 18.0024 16.6148 18.1899L15.6609 16.9688C15.6393 16.9408 15.6103 16.9196 15.5772 16.9074C15.544 16.8953 15.5081 16.8927 15.4736 16.9001C15.4391 16.9074 15.4073 16.9244 15.382 16.949C15.3567 16.9735 15.3388 17.0048 15.3305 17.0391L14.4023 20.8406C14.3742 20.9578 14.4633 21.0727 14.5828 21.0727L18.4969 21.0914C18.6539 21.0914 18.743 20.9109 18.6445 20.7891L17.7703 19.6664Z"
              fill="#556584" />
          </svg>
        </button>
      
        <div class="separator">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="2"
            viewBox="0 0 48 2"
            fill="none">
            <path
              d="M1 1H47"
              stroke="#F2F3F4"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </div>
      }
  
        <button class="action-button" (click)="zoomOut()">
          <svg
            class="tools"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none">
            <path
              d="M19.7316 18.4431L16.102 14.8135C17.5743 13.0107 18.2975 10.7111 18.1221 8.39011C17.9467 6.06914 16.8861 3.90432 15.1595 2.34328C13.433 0.782236 11.1727 -0.055649 8.84581 0.00287136C6.51895 0.0613918 4.30356 1.01184 2.6577 2.6577C1.01184 4.30356 0.0613918 6.51895 0.00287136 8.84581C-0.055649 11.1727 0.782236 13.433 2.34328 15.1595C3.90432 16.8861 6.06914 17.9467 8.39011 18.1221C10.7111 18.2975 13.0107 17.5743 14.8135 16.102L18.4431 19.7316C18.5274 19.8166 18.6278 19.8841 18.7384 19.9302C18.849 19.9763 18.9676 20 19.0873 20C19.2071 20 19.3257 19.9763 19.4363 19.9302C19.5469 19.8841 19.6472 19.8166 19.7316 19.7316C19.8166 19.6472 19.8841 19.5469 19.9302 19.4363C19.9763 19.3257 20 19.2071 20 19.0873C20 18.9676 19.9763 18.849 19.9302 18.7384C19.8841 18.6278 19.8166 18.5274 19.7316 18.4431ZM9.10601 16.3652C7.67028 16.3652 6.2668 15.9394 5.07304 15.1418C3.87927 14.3441 2.94885 13.2104 2.39942 11.884C1.84999 10.5575 1.70624 9.09795 1.98633 7.68982C2.26643 6.28168 2.9578 4.98822 3.97301 3.97301C4.98822 2.9578 6.28168 2.26643 7.68982 1.98633C9.09795 1.70624 10.5575 1.84999 11.884 2.39942C13.2104 2.94885 14.3441 3.87927 15.1418 5.07304C15.9394 6.2668 16.3652 7.67028 16.3652 9.10601C16.3652 11.0313 15.6004 12.8776 14.239 14.239C12.8776 15.6004 11.0313 16.3652 9.10601 16.3652Z"
              fill="#556584" />
            <path
              d="M11.8278 8.19875H10.013V6.38396C10.013 6.1433 9.91745 5.9125 9.74728 5.74233C9.57711 5.57216 9.34631 5.47656 9.10565 5.47656C8.865 5.47656 8.6342 5.57216 8.46403 5.74233C8.29386 5.9125 8.19826 6.1433 8.19826 6.38396V8.19875H6.38347C6.14281 8.19875 5.91201 8.29435 5.74184 8.46452C5.57167 8.63468 5.47607 8.86548 5.47607 9.10614C5.47607 9.3468 5.57167 9.57759 5.74184 9.74776C5.91201 9.91793 6.14281 10.0135 6.38347 10.0135H8.19826V11.8283C8.19826 12.069 8.29386 12.2998 8.46403 12.4699C8.6342 12.6401 8.865 12.7357 9.10565 12.7357C9.34631 12.7357 9.57711 12.6401 9.74728 12.4699C9.91745 12.2998 10.013 12.069 10.013 11.8283V10.0135H11.8278C12.0685 10.0135 12.2993 9.91793 12.4695 9.74776C12.6396 9.57759 12.7352 9.3468 12.7352 9.10614C12.7352 8.86548 12.6396 8.63468 12.4695 8.46452C12.2993 8.29435 12.0685 8.19875 11.8278 8.19875Z"
              fill="#556584" />
          </svg>
        </button>
  
        <button class="action-button" (click)="zoomIn()">
          <svg
            class="tools"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none">
            <path
              d="M19.7316 18.4431L16.102 14.8135C17.5743 13.0107 18.2975 10.7111 18.1221 8.39011C17.9467 6.06914 16.8861 3.90432 15.1595 2.34328C13.433 0.782236 11.1727 -0.055649 8.84581 0.00287136C6.51895 0.0613918 4.30356 1.01184 2.6577 2.6577C1.01184 4.30356 0.0613918 6.51895 0.00287136 8.84581C-0.055649 11.1727 0.782236 13.433 2.34328 15.1595C3.90432 16.8861 6.06914 17.9467 8.39011 18.1221C10.7111 18.2975 13.0107 17.5743 14.8135 16.102L18.4431 19.7316C18.5274 19.8166 18.6278 19.8841 18.7384 19.9302C18.849 19.9763 18.9676 20 19.0873 20C19.2071 20 19.3257 19.9763 19.4363 19.9302C19.5469 19.8841 19.6472 19.8166 19.7316 19.7316C19.8166 19.6472 19.8841 19.5469 19.9302 19.4363C19.9763 19.3257 20 19.2071 20 19.0873C20 18.9676 19.9763 18.849 19.9302 18.7384C19.8841 18.6278 19.8166 18.5274 19.7316 18.4431ZM9.10601 16.3652C7.67028 16.3652 6.2668 15.9394 5.07304 15.1418C3.87927 14.3441 2.94885 13.2104 2.39942 11.884C1.84999 10.5575 1.70624 9.09795 1.98633 7.68982C2.26643 6.28168 2.9578 4.98822 3.97301 3.97301C4.98822 2.9578 6.28168 2.26643 7.68982 1.98633C9.09795 1.70624 10.5575 1.84999 11.884 2.39942C13.2104 2.94885 14.3441 3.87927 15.1418 5.07304C15.9394 6.2668 16.3652 7.67028 16.3652 9.10601C16.3652 11.0313 15.6004 12.8776 14.239 14.239C12.8776 15.6004 11.0313 16.3652 9.10601 16.3652Z"
              fill="#556584" />
            <rect
              x="5.33398"
              y="8"
              width="7.33333"
              height="2"
              rx="1"
              fill="#556584" />
          </svg>
        </button>
      </div>
  
      <div class="assets-container">
        @if (this.templateData?.template_type?.toLowerCase() !== 'picklist' && showHighlightCheckbox) {
          <label>
            <input type="checkbox" (change)="toggleHighlight($event)" [(ngModel)]="highlightCustomizable" />
            Highlight customizable sections
          </label>
        }
        <!-- 
              @if (showResetIcon) {
                <div class="reset-icon" (click)="resetCustomizableSection()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-bootstrap-reboot"
                    viewBox="0 0 16 16">
                    <path
                      d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.8 6.8 0 0 0 1.16 8z" />
                    <path
                      d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324z" />
                  </svg>
                </div>
              } -->
  
              @if (isAccountExecutive || isCustomer) {
                <div class="language-dropdown-container">
                  @if(shouldShowLanguageDropdown()){
                  <label class="language-label">Select language</label>
                  <mat-form-field appearance="outline" class="language-dropdown">
                    <mat-select [(value)]="selectedLanguage" (selectionChange)="onLanguageChange($event)">
                      @for (lang of languages; track lang) {
                      <mat-option [value]="lang">
                        {{ lang }}
                      </mat-option>
                    }
                    </mat-select>
                  </mat-form-field>
                  <div class="dropdown-line"></div>
                }
                </div>              
            }
  
        <div class="assets-list">
          @for (asset of mappedAssets; track asset) {
            <div>
              <img [src]="asset" alt="Asset" (click)="onAssetClick(asset)" />
            </div>
          }
        </div>
        @if (this.config_type === 'list') {
          <div class="listview">
            <app-listview
              #listView
              [module_configuration]="element_mapping_config.module_configuration"
              [job_code]="jobCode"
              [presignedurls]="presignedurls"
              (selectedConfig)="drawSelectedConfig($event)"
              (onListViewLoaded)="onListViewLoaded($event)"
              ></app-listview>
          </div>
        }

        @if (this.config_type == 'dropdown' || this.templateData?.template_type?.toLowerCase() == 'picklist') {
          <div class="dropdown">
            <app-dropdown [templateType]="templateData?.template_type" 
              [mappings]="templateData?.mappings"
              [module_configuration]="templateData?.module_configuration" 
              (selectedConfigDropDown)="drawSelectedDropDownConfig($event)" 
              (selectedConfig)="drawSelectedConfig($event)"
              (onDropdownLoaded)="onListViewLoaded($event)">
            </app-dropdown>
          </div>
        }
      </div>
    </div>
  </div>
  </div>
  
  <app-preview-template></app-preview-template>
  