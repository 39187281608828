import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent implements OnInit, OnChanges {
  @Input() placeholder = 'Search...';
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  searchText = '';

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['placeholder']) {
      this.searchText = '';
    }
  }

  ngOnInit() {
    // const routePath = this.route.firstChild?.snapshot.routeConfig?.path ?? '';
    // this.searchService.getSearchQuery$(routePath).subscribe(query => {
    //   this.searchText = query;
    // });
  }

  onSearch() {
    this.search.emit(this.searchText);
  }

  // ngOnDestroy(): void {
  //   this.searchService.clearSearchQuery(this.routePath);
  // }
}
