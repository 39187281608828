<div class="right-nav">
  <tbt-tabs>
    <tbt-tab-item title="Properties">
      <div>
        @if (false) {
        <div class="demographic-section">
          <!-- <app-demographic></app-demographic> -->
        </div>
        }
        <div class="dimension-controls">
          <div class="dimension-group">
            <label for="width">Width:</label>
            <input type="number" id="width" [(ngModel)]="width" />
          </div>
          <div class="dimension-group">
            <label for="height">Height:</label>
            <input type="number" id="height" [(ngModel)]="height" />
          </div>
        </div>
        @if(currentElement?.type === 'text'){
        <div class="card mt-4">
          <div class="card-header">
            <span>Text Properties</span>
          </div>
          <div class="d-flex align-items-baseline justify-content-center">
            <textarea [(ngModel)]="editedText" placeholder="Enter text"
              class="form-control add-text-input col-sm-6 w-75 mt-2"></textarea>
            <button (click)="EditTextElement()" class="btn btn-outline-primary ms-1 col-sm-2 button">
              Apply
            </button>
          </div>
          <div class="card-body">
            <div class="element-properties">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 disp-flex">
                      <label for="color" class="noWrap custm-widt-5">Select Text Color:</label>
                      <input type="color" id="color" [ngModel]="currentElement?.color"
                        (ngModelChange)="currentElement && (currentElement.color = $event)" class="form-control ms-2"
                        (input)="updateElementDimensions()" />
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-12 disp-flex">
                      <label for="fontFamily" class="noWrap custm-widt-5">Font Family:</label>
                      <select id="fontFamily" [ngModel]="currentElement?.fontFamily"
                        (ngModelChange)="currentElement && (currentElement.fontFamily = $event)" class="form-control"
                        (change)="updateElementDimensions()">
                        @for (font of fontFamilies; track font) {
                        <option [value]="font">{{ font }}</option>
                        }
                      </select>
                    </div>
                  </div>


                  <div class="row mt-2">
                    <div class="col-12 disp-flex">
                      <label for="fontSize" class="noWrap custm-widt-5">Font Size:</label>
                      <input type="number" id="fontSize" [ngModel]="currentElement?.fontSize"
                        (ngModelChange)="currentElement && (currentElement.fontSize = $event)" class="form-control ms-2"
                        (input)="updateElementDimensions()" />
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-12 disp-flex">
                      <label for="backgroundColor" class="noWrap custm-widt-5">Background Color:</label>
                      <input type="color" id="backgroundColor" [ngModel]="currentElement?.backgroundColor"
                        (ngModelChange)="currentElement && (currentElement.backgroundColor = $event)"
                        class="form-control ms-2" (input)="updateElementDimensions()" />
                      <button (click)="removeBackgroundColor()" class="btn btn-link ms-2" style="font-size: 14px;">
                        No Background
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }

        @if (currentElement) {
        <div class="static-control">
          <label>
            <input type="checkbox" [checked]="currentElement.customizable" (change)="toggleStaticElement()" />
            Is customizable section?
          </label>
        </div>
        }
      </div>
    </tbt-tab-item>
    <tbt-tab-item title="Mappings" [disabled]="false">
      <div class="mappings-section">
        <app-mappings></app-mappings>
      </div>
    </tbt-tab-item>
  </tbt-tabs>
  <div class="flex-grow"></div>
  <!-- Navigation buttons for layers -->
  <div class="row justify-content-center">
    <div class="col-auto">
      <div class="layer-navigation btn-group" role="group" aria-label="Layer Navigation">
        <button type="button" class="btn btn-secondary btn-lg px-4" (click)="moveLayer('up')">
          <span [innerHTML]="btnIconType('up')"></span>
        </button>
        <button type="button" class="btn btn-secondary btn-lg px-4" (click)="moveLayer('down')">
          <span [innerHTML]="btnIconType('down')"></span>
        </button>
        <button type="button" class="btn btn-secondary btn-lg px-4" (click)="moveLayer('upTop')">
          <span [innerHTML]="btnIconType('upTop')"></span>
        </button>
        <button type="button" class="btn btn-secondary btn-lg px-4" (click)="moveLayer('downTop')">
          <span [innerHTML]="btnIconType('downTop')"></span>
        </button>
      </div>
    </div>
  </div>
</div>