import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem('access_token');

    const url = state.url;
    const idpParam = this.getQueryParam(url, 'idp');

    if (idpParam) {
      return this.checkIdpAuthentication(idpParam);
    } else {
      if (token) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    }
  }

  private getQueryParam(url: string, param: string): string | null {
    const queryParams = new URLSearchParams(url.split('?')[1]);

    // get the params and store in local storage

    const idp = queryParams.get('idp');
    const authToken = queryParams.get('authtoken');
    const role = queryParams.get('role');
    const jobCode = queryParams.get('jobcode');
    const templateId = queryParams.get('templateid');

    if (idp) {
      localStorage.setItem('idp', idp);
    }
    if (authToken) {
      localStorage.setItem('authToken', authToken);
    }
    if (role) {
      localStorage.setItem('userRole', role);
    }
    if (jobCode) {
      localStorage.setItem('jobCode', jobCode);
    }
    if (templateId) {
      localStorage.setItem('templateId', templateId);
    }

    return queryParams.get(param);
  }

private checkIdpAuthentication(idp: string): boolean {
    if (idp && idp === 'SF') {
      return true;
    } else {
      // this.router.navigate(['/']);
      return false;
    }
}

}
export const authGuard: CanActivateFn = (route, state) => {
  return true;
};
