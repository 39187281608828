import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CanvasElement } from '../models/canvas-element';
import { Observable, of, Subject } from 'rxjs';
import data from '../../../../assets/static-data/dm.json';
import { CanvasElementConfig, TemplateConfig } from '../models/template-config';
import { ElementConfig } from '../models/element-config';
import { HttpClient } from '@angular/common/http';
import { TemplateService } from '@app/shared/services/template.service';
import { reset, setZoneStatus, updateDimension } from '@app/shared/store/actions/properties.actions';
import { Store } from '@ngrx/store';
import { IElementConfig } from '../models/config-mapping.model';
import { TbtStorageService } from '@app/shared/services/tbt-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CanvasEditorService {

  private readonly setElementPropertiesSubject = new BehaviorSubject<CanvasElement | null>(null);
  private readonly propertiesUpdateSubject = new Subject<CanvasElement>();
  private readonly navBarEventSubject = new Subject<string>();
  private readonly imageAddedSubject = new Subject<string>();
  private readonly imageRemovedSubject = new Subject<string>();
  navBarEvent$ = this.navBarEventSubject.asObservable();
  private readonly elementsChangedSubject = new Subject<CanvasElement[]>();
  private readonly elementSelectedSubject = new Subject<CanvasElement | null>();
  private readonly selectedModulesSubject = new BehaviorSubject<string[]>([]);
  private templateConfig: TemplateConfig = new TemplateConfig();
  private readonly elementMoveSubject = new Subject<number>();
  private readonly unsavedChangesSource = new BehaviorSubject<boolean>(false);
  unsavedChanges$ = this.unsavedChangesSource.asObservable();
  private readonly selectedElementSource = new BehaviorSubject<CanvasElementConfig | null>(null);
  selectedElement$ = this.selectedElementSource.asObservable();
  private readonly commentsSubject = new BehaviorSubject<string>('');
  comments$ = this.commentsSubject.asObservable();
  private readonly templateService = inject(TemplateService);
  private readonly tbtStorageService = inject(TbtStorageService);
  private readonly elements: ElementConfig[] = [];
  selectedElement!: CanvasElement;

  constructor() {
    const savedConfig = this.tbtStorageService.getItem('templateData$');
    if (savedConfig) {
      this.templateConfig = JSON.parse(savedConfig) as TemplateConfig;
    }
  }

  setElementProperties(element: CanvasElement | null): void {
      this.setElementPropertiesSubject.next(element);
      if (element) {
        console.log(element);
        this.selectedElement = element;
        this.elementSelectedSubject.next(element);
        const elementConfig: CanvasElementConfig = {
          type: element.type,
          x: element.x,
          y: element.y,
          targetX: element.targetX,
          targetY: element.targetY,
          text: element.text,
          element_name: element.element_name,
          element_url: element.element_url,
          width: element.width,
          height: element.height,
          customizable: element.customizable ?? false,
          page_number: element.page_number ?? 0,
          color: element.color,
          font_family: element.font_family,
          font_size: element.font_size,
          backgroundColor: element.backgroundColor,
          is_deleted:element.is_deleted,
          is_dirty:element.is_dirty,
          section_id: element.section_id,
        };
        if(element.id){
          elementConfig.id = element.id
        }
        const matchingConfigs = this.templateConfig.canvas_configuration.filter(
          config => config.section_id === elementConfig.section_id
        );
        
        if (matchingConfigs.length > 0) {
          this.templateConfig.canvas_configuration = this.templateConfig.canvas_configuration.map(config => {
            if (config.section_id === elementConfig.section_id) {
              return {
                ...config,
                ...elementConfig
              };
            }
            return config;
          });
        } else {
          this.templateConfig.canvas_configuration.push(elementConfig);
        }
        
        this.selectedElementSource.next(elementConfig);
        // this.store.dispatch(updateDimension({ width: element?.width, height: element?.height }))
        // this.store.dispatch(setZoneStatus({ value: element?.customizable ?? false }))
      } else {
  
        console.log("else element");
        this.selectedElementSource.next(null);
        //this.store.dispatch(reset())
      }
      this.setUnsavedChanges(true);
    }
  
    getElements(): ElementConfig[] {
      return this.elements;
    }
  
    updateElementDimensions(selectedElement: CanvasElement): void {
      this.propertiesUpdateSubject.next(selectedElement);
      this.setUnsavedChanges(true);
    }
  
    updateSaveInteraction(eventName: string) {
      this.navBarEventSubject.next(eventName);
      this.setUnsavedChanges(false);
    }
  
    notifyImageAdded(fileName: string) {
      this.imageAddedSubject.next(fileName);
      this.setUnsavedChanges(true);
    }
  
    notifyImageRemoved(fileName: string) {
      this.imageRemovedSubject.next(fileName);
      this.setUnsavedChanges(true);
    }
  
    getImageAddedObservable() {
      return this.imageAddedSubject.asObservable();
    }
  
    getImageRemovedObservable() {
      return this.imageRemovedSubject.asObservable();
    }
  
    getNavBarEventsObservable() {
      return this.navBarEventSubject.asObservable();
    }
  
    getElementPropertySetObservable() {
      return this.setElementPropertiesSubject.asObservable();
    }
  
    getElementPropertyChangeObservable() {
      return this.propertiesUpdateSubject.asObservable();
    }
  
    getTemplateLayers(): Observable<any> {
      return of(data);
    }
  
    getModuleAssets() {
      // return of(data.Modules);
      return null;
    }
  
    notifyElementsChanged(elements: CanvasElement[]) {
      this.elementsChangedSubject.next(elements);
      this.setUnsavedChanges(true);
    }
  
    getElementsChangedObservable() {
      return this.elementsChangedSubject.asObservable();
    }
  
    getElementSelectedObservable() {
      return this.elementSelectedSubject.asObservable();
    }
  
    updateSelectedModules(modules: string[], moduleId: string, moduleName: string): void {
      const zoneId = this.selectedElement.section_id;
      const id = this.selectedElement.id;
  
      if (!zoneId || !moduleId) {
        console.error(' Missing zone ID or module ID');
        return;
      }
  
      if (!modules.length) {
        const mappingExist = this.templateConfig.mappings.find(
          mapping => mapping.zone_id === zoneId && mapping.module_id === moduleId
        );
  
        if (mappingExist) {
          if (mappingExist.id) {
            mappingExist.is_deleted = true;
            mappingExist.is_dirty = true;
          } else {
            this.templateConfig.mappings = this.templateConfig.mappings.filter(
              mapping => mapping !== mappingExist
            );
          }
        } else {
          console.warn('No existing mapping found ', zoneId);
        }
  
        this.setUnsavedChanges(true);
        return;
      }
  
      const mappingExist = this.templateConfig.mappings.find(
        mapping =>
          mapping.zone_id === zoneId && mapping.module_id === moduleId
      );
  
      if (mappingExist) {
        mappingExist.module_id = moduleId;
        mappingExist.module_name = moduleName;
        mappingExist.is_deleted = false;
        mappingExist.is_dirty = true;
      } else {
        const mappingZone = this.templateConfig.mappings.find(
          mapping => mapping.zone_id === zoneId
        );
  
        if (mappingZone) {
          mappingZone.module_name = moduleName;
          mappingZone.module_id = moduleId;
          mappingZone.is_deleted = false;
          mappingZone.is_dirty = true;
        } else {
          const mappingNew = {
            zone_id: zoneId,
            module_name: moduleName,
            module_id: moduleId,
            is_deleted: false,
            is_dirty: true,
          };
          this.templateConfig.mappings.push(mappingNew);
        }
      }
      this.setUnsavedChanges(true);
    }
  
    getSelectedModulesObservable(): Observable<string[]> {
      return this.selectedModulesSubject.asObservable();
    }
  
    set setTemplateConfig(value: TemplateConfig) {
      if (!Array.isArray(value.mappings)) {
        console.error('Invalid mappings format:', value.mappings);
        value.mappings = [];
      }
      this.templateConfig = value;
      this.setUnsavedChanges(false);
    }
  
    get getTemplateConfig(): TemplateConfig {
      return this.templateConfig;
    }
  
    doSave(
      masterTemplateConfig: any,
      actionType: 'save' | 'submit',
      comments?: string,
      status?: string,
      saveModuleConfig?: string
    ) {
  
      let storedDetails = this.templateService.getStoredTemplateDetails();
      let finalData = this.getTemplateConfig;
      finalData.method_code = '2';
      finalData.job_code = storedDetails.jobCode;
      finalData.user_id = storedDetails.userId;
      finalData.template_id = storedDetails.templateId;
      finalData.design_name = storedDetails.templateName;
      finalData.status = status || storedDetails.templateStatus;
      if (actionType === 'submit') {
        finalData.comments = comments || '';
      } else {
        delete finalData.comments;
      }
      finalData.canvas_configuration = this.templateConfig.canvas_configuration;
      if (saveModuleConfig) {
        finalData.module_configuration = this.templateConfig.module_configuration;
      } else {
        finalData.module_configuration = [];
      }
      finalData.template_approver = storedDetails.template_approver;
      this.setTemplateConfig = finalData;
      this.setUnsavedChanges(false);
  
      console.log("--------------------------------");
      console.log(finalData)
  
      return new Promise((resolve,reject)=>{
        this.templateService.updateTemplate(finalData).subscribe(
          response => {
            console.log('Template saved successfully:', response);
            this.setUnsavedChanges(false);
            
            // TODO: Enable Submit only after savin all the changes.
            // this.templateService.getTemplateById(this.templateConfig.template_id).subscribe({
            //   next: (templateData) => {
            //     console.log('', templateData);
            //   },
            //   error: (error) => {
            //     console.error('Error getting template details:', error);
            //   }
            // });
            resolve(response);
          },
          error => {
            console.error('Error saving template:', error);
            reject(error);
          }
        );
      })
    }
  
    notifyElementMove(direction: number): void {
      this.elementMoveSubject.next(direction);
    }
  
    getElementMoveObservable() {
      return this.elementMoveSubject.asObservable();
    }
    setUnsavedChanges(changes: boolean) {
      this.unsavedChangesSource.next(changes);
    }
    hasCustomisableSection(): boolean {
      return this.templateConfig.canvas_configuration.some(config => config.customizable);
    }
    setComments(comments: string) {
      this.commentsSubject.next(comments);
    }
  
    getComments(): string {
      return this.commentsSubject.value;
    }
  
    setModuleConfiguration(module_config: IElementConfig) {
  
     // if(!this.templateConfig.module_configuration){
    this.templateConfig.module_configuration = [];
     // }
     this.templateConfig.module_configuration.push(module_config);
    }
  
    setCanvasConfiguration(elementConfig: ElementConfig[]) {
      let exportData: CanvasElementConfig[] = elementConfig.map(element => {
  
        const elementObject:CanvasElementConfig = {
          type: element.type,
          section_id: element.section_id,
          x: element.x,
          y: element.y,
          text: element.text,
          element_name: element.element_name,
          element_url: element.element_url,
          width: element.width,
          height: element.height,
          customizable: element.customizable ?? false,
          page_number: element.page_number ?? 0,
          color: element.color,
          font_family: element.font_family,
          font_size: element.font_size,
          backgroundColor: element.backgroundColor,
          is_deleted:element.is_deleted,
          is_dirty:element.is_dirty
          }
  
        if(element.id){
          elementObject.id = element.id;
        }
        return elementObject;
      });
  
      this.templateConfig.canvas_configuration = exportData;
  
  }
}
