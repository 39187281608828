<h1 mat-dialog-title>Configure module</h1>
<mat-dialog-content class="mat-typography">
  <div class="form">
    <form #configForm="ngForm" #form="ngForm">
      <div class="section1">
        <div class="form-row">
          <mat-form-field appearance="outline" style="width: 48%;">
            <mat-label>Label</mat-label>
            <input matInput type="text" id="label" [(ngModel)]="configElement.label" name="label" required />
            @if (configForm.controls['label']?.invalid && configForm.controls['label']?.touched) {
            <mat-error>
              Label is required.
            </mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 48%;">
            <mat-label>Select Type</mat-label>
            <mat-select [(ngModel)]="configElement.type" (selectionChange)="onTypeChange()" name="type" required>
              <mat-option value="image">Image</mat-option>
              <mat-option value="dropdown">Dropdown</mat-option>
              <mat-option value="list">List</mat-option>
            </mat-select>
            @if (configForm.controls['type']?.invalid && configForm.controls['type']?.touched) {
            <mat-error>
              Type is required.
            </mat-error>
            }
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="outline" style="width: 48%;">
            <mat-label>Select module</mat-label>
            <mat-select [(ngModel)]="configElement.module" name="module" required (selectionChange)="loadDesigns()">
              @for (module of moduleData; track module) {
              <mat-option [value]="module">{{module.name}}</mat-option>
              }
            </mat-select>
            @if (configForm.controls['module']?.invalid && configForm.controls['module']?.touched) {
            <mat-error>
              Module is required.
            </mat-error>
            }
          </mat-form-field>
        </div>
      </div>

      @if(configElement.type == 'dropdown' || configElement.type == 'list'){
      <div class="section2">
        <div class="optionsDiv" style="display: flex; justify-content: space-between; margin-top: 30px;">
          <div style="width: 48%;">
            <p class="optionTitle">Create options</p>
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Enter option text</mat-label>
              <input matInput type="text" id="options" [(ngModel)]="configOption.label" name="optionLabel" />
            </mat-form-field>
            @if(configElement.type == 'dropdown'){

              @if(templateData?.template_type?.toLowerCase() === 'picklist'){

                <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>Images</mat-label>
                  <mat-select [(ngModel)]="configOption.thumbnail" name="thumbnail"
                    (selectionChange)="updateThumbnail($event.value)" multiple>
                    @for (asset of filteredAssets; track asset) {
                    <mat-option [value]="asset.key">
                      <img [src]="asset.url" alt="asset" class="asset-img"
                        style="width: 30px; height: 30px; margin-right: 10px;">
                      {{asset.key}}
                    </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              } @else {
                <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>Images</mat-label>
                  <mat-select [(ngModel)]="configOption.thumbnail" name="thumbnail"
                              (selectionChange)="updateThumbnail($event.value)">
                    @for (asset of filteredAssets; track asset) {
                      <mat-option [value]="asset.key">
                        <img [src]="asset.url" alt="asset" class="asset-img"
                             style="width: 30px; height: 30px; margin-right: 10px;">
                        {{asset.key}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }



            }
            @else{
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Images</mat-label>
              <mat-select [(ngModel)]="configOption.thumbnail" name="thumbnail"
                (selectionChange)="updateThumbnail($event.value)">
                @for (asset of filteredAssets; track asset) {
                <mat-option [value]="asset.key">
                  <img [src]="asset.url" alt="asset" class="asset-img"
                    style="width: 30px; height: 30px; margin-right: 10px;">
                  {{asset.key}}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
            }

            <mat-form-field appearance="outline" style="width: 30%;">
              <mat-label>Order</mat-label>
              <input matInput type="number" id="optionOrder" [(ngModel)]="configOption.order" name="optionOrder" />
            </mat-form-field>
            <button mat-button (click)="addOption()" appearance="outline" class="button"
              style="display: block; width: 125px;margin-bottom: 10px;">
              Add option
            </button>
          </div>
          <div class="optionPreviewDiv">
            <label class="optionPreviewTitle">Preview</label>
            <div class="option-list-container">
              @for (option of configElement.options; track option) {
              <div class="option-item">
                @if (option.thumbnail && getAssetUrl(option.thumbnail)) {
                <img [src]="getAssetUrl(option.thumbnail)" alt="Thumbnail" class="thumbnail"
                  style="width: 30px; height: 30px; margin-right: 10px;">
                }
                <label>{{ option.label }}</label>
              </div>
              }
              @empty{
              <div> No preview available</div>
              }
            </div>
          </div>
        </div>
      </div>
      }
      <div class="form-row" style="margin-top: 30px;">
        <!--  @if(configElement.type == 'dropdown' || configElement.type == 'list'){
        <mat-form-field appearance="outline" style="width: 60%;">
          <mat-label>Number of selection</mat-label>
          <input matInput type="number" id="noOfSelection" [(ngModel)]="configElement.noOfSelection"
            name="noOfSelection" required />
          @if (configForm.controls['noOfSelection']?.invalid && configForm.controls['noOfSelection']?.touched) {
          <mat-error>
            Number of selection is required.
          </mat-error>
          }
        </mat-form-field>
        } -->
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Help Text</mat-label>
          <input matInput type="text" id="helpText" [(ngModel)]="configElement.helpText" name="helpText" required />
          @if (configForm.controls['helpText']?.invalid && configForm.controls['helpText']?.touched) {
          <mat-error>
            Help Text is required.
          </mat-error>
          }
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Validation Error Message</mat-label>
        <input matInput type="text" id="validationMessage" [(ngModel)]="configElement.validationError"
          name="validationError" required />
        @if (configForm.controls['validationError']?.invalid && configForm.controls['validationError']?.touched) {
        <mat-error>
          Validation Error Message is required.
        </mat-error>
        }
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="button cancel-button">Cancel</button>
  <button mat-button  cdkFocusInitial class="button save-button" [disabled]="configForm.invalid"
    (click)="submitForm()">Save</button>
</mat-dialog-actions>
