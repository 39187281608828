import { Component, OnInit,OnDestroy, inject } from '@angular/core';
import { CanvasElementConfig, MappingElements } from '../../models/template-config';
import { CanvasEditorService } from '../../services/canvas-editor.service';
import { MappingsComponent } from '../mappings/mappings.component';
import jsPDF from 'jspdf';
import { ImageCacheS3Service } from '../../services/image-cache-s3.service';

@Component({
  selector: 'app-test-template',
  standalone: true,
  imports: [MappingsComponent],
  templateUrl: './test-template.component.html',
  styleUrl: './test-template.component.scss'
})
export class TestTemplateComponent implements OnInit, OnDestroy {
  assets: CanvasElementConfig[] = [];
  canvasConfig: CanvasElementConfig[] = [];
  private initialState = new Map<string, CanvasElementConfig>();
  selectedElement: CanvasElementConfig | null = null;
  selectedModule: string | null = null;
  private ctx: CanvasRenderingContext2D | null = null;
  private canvas: HTMLCanvasElement | null = null;
  private isHandlingClick = false;
  private imageCache: Map<string, HTMLImageElement> = new Map();
  mappedAssets: string[] = [];
  modules: any[] = [];

  private imageCacheS3Service = inject(ImageCacheS3Service);
  private cacheImages! : Record<string, HTMLImageElement>
  constructor(private canvasEditorService: CanvasEditorService) {}

  ngOnInit(): void {
    // this.canvasEditorService.getModuleAssets().subscribe((modules: any[]) => {
    //   this.modules = modules;
    // });

    this.imageCacheS3Service
      .preloadImages([
        'https://heq-tbt-resources.s3.amazonaws.com/US-JRC-00639/standard/static_files/image_module_zone.png?AWSAccessKeyId=ASIARKWSIAQTRFNYDCS4&Signature=4ei%2FIijE6bFEmpF%2FcHcmhWPu5Io%3D&x-amz-security-token=IQoJb3JpZ2luX2VjENb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkcwRQIgGkwTcfZPoE6UK%2BtxLubm8kLkSQsdC%2B8C%2BuN85RcFt80CIQDXO0neAABatzV%2FcG%2FsCqB3gK%2FxmdwR6BkacedUKsYLcCqSAwgvEAEaDDA5MTcwODk4MjMxMSIMlaoLkyjO8h0AyUNSKu8CWlDEODUrLUSY%2F6lQn8Kj5FfDEI%2B1F9xV8J8SXvhYkOGjT0TE4SgJBcSXJeuLxA2UsZ9yWjQ1MJA0Taewr72uRGwg9c35KuE7IXA3hU%2BdwPjwcULARnBmG5rdUDUNXGfqUleqXdaAmY%2FU7hwWYv2%2FXkk7JGvyKBQ4sXrMpFy7cxEQZlkXk86iMmNJhhJFtYpz8a8MwbNXlrqWQhxUCnzhm8uXhnaS8zdXGHVaajOmOuk0DchntO434eK2kIYUOUgvcLWWTa%2BBQse7ytTvsrRezdI44H7YnoE02aEcekbK2w6o2o23kUG7IsG7h11hyVtjVYL0nyCtrHJO9bDRWAP2KGzOGGAB7A2qnDg414cFsR4ev7nPE5iHvZGM2KVWPNY0bgntnZjjwgptX8Z%2BDpa3dJ8XY3me8Ijuzt6MXMEBAD7kx%2FocjeH%2Bl1PibtSF013L2PlSAqT9hBNfzPxCCwJtSuOFu6%2BvSG0FKTusmzvwuzDvx4%2B4BjqdAWjeN6hc2nAgNV%2Feyo1xoxZooQ4AOXCdGsTU2QxspG9jM%2FiBprnT%2BZl9n5UigELgRK1aItt9b2vlk90Esr5as6JRbBYTWmhmpbYZJGT91HK%2B8Fn6lPp2OEolRmqkdVVJP%2FCDuXqmjvmIYnH0rAy3KsGKxQ9pkkhCdSQd34ujPx3ojVGzxTtzbdKWuY0lTGgl3qttm31Ng40xrKlt7mM%3D&Expires=1728326808',
        'https://heq-tbt-resources.s3.amazonaws.com/US-JRC-00639/standard/static_files/pledge_module_zone.png?AWSAccessKeyId=ASIARKWSIAQTRFNYDCS4&Signature=jsME3fyFZNZ5sHNcveQpomSlbLU%3D&x-amz-security-token=IQoJb3JpZ2luX2VjENb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkcwRQIgGkwTcfZPoE6UK%2BtxLubm8kLkSQsdC%2B8C%2BuN85RcFt80CIQDXO0neAABatzV%2FcG%2FsCqB3gK%2FxmdwR6BkacedUKsYLcCqSAwgvEAEaDDA5MTcwODk4MjMxMSIMlaoLkyjO8h0AyUNSKu8CWlDEODUrLUSY%2F6lQn8Kj5FfDEI%2B1F9xV8J8SXvhYkOGjT0TE4SgJBcSXJeuLxA2UsZ9yWjQ1MJA0Taewr72uRGwg9c35KuE7IXA3hU%2BdwPjwcULARnBmG5rdUDUNXGfqUleqXdaAmY%2FU7hwWYv2%2FXkk7JGvyKBQ4sXrMpFy7cxEQZlkXk86iMmNJhhJFtYpz8a8MwbNXlrqWQhxUCnzhm8uXhnaS8zdXGHVaajOmOuk0DchntO434eK2kIYUOUgvcLWWTa%2BBQse7ytTvsrRezdI44H7YnoE02aEcekbK2w6o2o23kUG7IsG7h11hyVtjVYL0nyCtrHJO9bDRWAP2KGzOGGAB7A2qnDg414cFsR4ev7nPE5iHvZGM2KVWPNY0bgntnZjjwgptX8Z%2BDpa3dJ8XY3me8Ijuzt6MXMEBAD7kx%2FocjeH%2Bl1PibtSF013L2PlSAqT9hBNfzPxCCwJtSuOFu6%2BvSG0FKTusmzvwuzDvx4%2B4BjqdAWjeN6hc2nAgNV%2Feyo1xoxZooQ4AOXCdGsTU2QxspG9jM%2FiBprnT%2BZl9n5UigELgRK1aItt9b2vlk90Esr5as6JRbBYTWmhmpbYZJGT91HK%2B8Fn6lPp2OEolRmqkdVVJP%2FCDuXqmjvmIYnH0rAy3KsGKxQ9pkkhCdSQd34ujPx3ojVGzxTtzbdKWuY0lTGgl3qttm31Ng40xrKlt7mM%3D&Expires=1728326808',
        'https://heq-tbt-resources.s3.amazonaws.com/US-JRC-00639/standard/static_files/static.png?AWSAccessKeyId=ASIARKWSIAQTRFNYDCS4&Signature=Dsy85dVpPk5mtNGk2Z%2FnMhmKHEs%3D&x-amz-security-token=IQoJb3JpZ2luX2VjENb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkcwRQIgGkwTcfZPoE6UK%2BtxLubm8kLkSQsdC%2B8C%2BuN85RcFt80CIQDXO0neAABatzV%2FcG%2FsCqB3gK%2FxmdwR6BkacedUKsYLcCqSAwgvEAEaDDA5MTcwODk4MjMxMSIMlaoLkyjO8h0AyUNSKu8CWlDEODUrLUSY%2F6lQn8Kj5FfDEI%2B1F9xV8J8SXvhYkOGjT0TE4SgJBcSXJeuLxA2UsZ9yWjQ1MJA0Taewr72uRGwg9c35KuE7IXA3hU%2BdwPjwcULARnBmG5rdUDUNXGfqUleqXdaAmY%2FU7hwWYv2%2FXkk7JGvyKBQ4sXrMpFy7cxEQZlkXk86iMmNJhhJFtYpz8a8MwbNXlrqWQhxUCnzhm8uXhnaS8zdXGHVaajOmOuk0DchntO434eK2kIYUOUgvcLWWTa%2BBQse7ytTvsrRezdI44H7YnoE02aEcekbK2w6o2o23kUG7IsG7h11hyVtjVYL0nyCtrHJO9bDRWAP2KGzOGGAB7A2qnDg414cFsR4ev7nPE5iHvZGM2KVWPNY0bgntnZjjwgptX8Z%2BDpa3dJ8XY3me8Ijuzt6MXMEBAD7kx%2FocjeH%2Bl1PibtSF013L2PlSAqT9hBNfzPxCCwJtSuOFu6%2BvSG0FKTusmzvwuzDvx4%2B4BjqdAWjeN6hc2nAgNV%2Feyo1xoxZooQ4AOXCdGsTU2QxspG9jM%2FiBprnT%2BZl9n5UigELgRK1aItt9b2vlk90Esr5as6JRbBYTWmhmpbYZJGT91HK%2B8Fn6lPp2OEolRmqkdVVJP%2FCDuXqmjvmIYnH0rAy3KsGKxQ9pkkhCdSQd34ujPx3ojVGzxTtzbdKWuY0lTGgl3qttm31Ng40xrKlt7mM%3D&Expires=1728326808'
      ])
      .then((data: any) => {
        console.log('completed: ', data);
        this.cacheImages = this.imageCacheS3Service.getCacheImages();
        this.loadCanvasConfiguration();

      })
      .catch((err: any) => {
        console.log('Error: ', err);
      })
      .finally(() => {
        console.log('ALL DONE!');
      });
  }

  ngOnDestroy(): void {
    if (this.canvas) {
      this.canvas.removeEventListener('click', this.handleCanvasClick.bind(this));
    }
  }


  loadCanvasConfiguration(): void {
    const canvasConfig = localStorage.getItem('templateConfig');
    if (canvasConfig) {
      const parsedConfig = JSON.parse(canvasConfig);
      this.canvasConfig = parsedConfig.canvas_configuration;
      this.canvasConfig.forEach(element => {
        if(element.id){
          this.initialState.set(element.id, { ...element });
        }
      });
      this.initializeCanvas();
    }
  }

  initializeInitialState(): void {
    this.canvasConfig.forEach(config => {
      if(config.id){
      this.initialState.set(config.id, { ...config });
      }
    });
  }

  initializeCanvas(): void {
    this.canvas = document.getElementById('testCanvas') as HTMLCanvasElement;
    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d');
      if (this.ctx) {
        this.redrawCanvas();
        this.canvas.addEventListener('click', this.handleCanvasClick.bind(this));
      }
    }
  }

  handleCanvasClick(event: MouseEvent): void {
    if (this.isHandlingClick) return;
    this.isHandlingClick = true;

    if (this.canvas) {
      const rect = this.canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const clickedElements = this.canvasConfig.filter(element =>
        x >= element.x &&
        x <= element.x + element.width &&
        y >= element.y &&
        y <= element.y + element.height &&
        element.customizable
      );

      if (clickedElements.length > 0) {
        const clickedElement = clickedElements[clickedElements.length - 1];
        this.selectElement(clickedElement);
        this.redrawCanvas();
      }

      setTimeout(() => { this.isHandlingClick = false; }, 200);
    }
  }

  drawBorderIfSelected(ctx: CanvasRenderingContext2D, element: CanvasElementConfig): void {
    if (this.selectedElement === element) {
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      ctx.strokeRect(element.x, element.y, element.width, element.height);
    }
  }

  redrawCanvas(): void {
    if (this.ctx && this.canvas) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.canvasConfig.forEach(element => {
        if (element.type === 'image' && element.element_url) {
          const cachedImage = this.imageCache.get(element.element_url);
          if (cachedImage) {
            this.ctx!.drawImage(cachedImage, element.x, element.y, element.width, element.height);
          } else {
            const img = new Image();

            const cacheImage = this.cacheImages[element.element_url];
            if (cacheImage) {
              img.src = cacheImage.src;
              console.log('::: FROM CACHE::: ');
            } else {
              img.src = element.element_url;
              console.log('::: FROM LINK::: ');
            }

            // img.src = this.imageCacheS3Service.getImage(element.element_url)?.src;
            img.onload = () => {
              this.imageCache.set(element.element_url, img);
              this.ctx!.drawImage(img, element.x, element.y, element.width, element.height);
              this.drawBorderIfSelected(this.ctx!, element);
            };
            img.onerror = () => {
              console.error('Failed to load image:', img.src);
            };
          }
        } else if (element.type === 'text') {
          this.ctx!.font = `${element.height}px Arial`;
          this.ctx!.fillText(element.element_url, element.x, element.y);
          this.drawBorderIfSelected(this.ctx!, element);
        }

        this.drawBorderIfSelected(this.ctx!, element);
      });
    }
  }


  selectElement(element: CanvasElementConfig): void {
    this.selectedElement = element;
    this.selectedModule = this.getSelectedModuleForElement(element);

    this.updateMappedAssets();

    this.canvasEditorService.setElementProperties(element);
  }

  updateMappedAssets(): void {
    if (this.selectedModule) {
      const module = this.modules.find((m: any) => m.module_name === this.selectedModule);
      this.mappedAssets = module ? module.assets : [];
    } else {
      this.mappedAssets = [];
    }
  }




  onImageSelected(asset: CanvasElementConfig): void {

    if (this.selectedElement && this.selectedElement.type === 'image') {
      if (asset.element_url) {
        this.selectedElement.element_url = asset.element_url;
        this.imageCache.delete(asset.element_url);
        this.redrawCanvas();
      } else {
        console.error('fileURL is undefined in asset:', asset);
      }
    }
  }



  getSelectedModuleForElement(element: CanvasElementConfig): string | null {
    const rawMappings = this.canvasEditorService.getTemplateConfig.mappings;

    const mappings: MappingElements[] = Array.isArray(rawMappings)
      ? rawMappings
      : Object.values(rawMappings);

    if (!Array.isArray(mappings)) {
      console.error('Mappings is not an array:', mappings);
      return null;
    }

    const selectedElementMapping = mappings.find((x: MappingElements) => x.zone_id === element.id);
    return selectedElementMapping ? selectedElementMapping.module_name : null;
  }



  resetSelectedElement(): void {
    if (this.selectedElement && this.selectedElement.id) {
      const initialConfig = this.initialState.get(this.selectedElement.id);

      if (initialConfig) {
        const index = this.canvasConfig.findIndex(e => e.id === this.selectedElement!.id);

        if (index !== -1) {
          this.canvasConfig[index] = { ...initialConfig };
          this.redrawCanvas();
        } else {
          console.warn('Selected element not found in canvasConfig');
        }
      } else {
        console.warn('Initial state not found for the selected element');
      }
    } else {
      console.warn('No element selected to reset');
    }
  }

  onAssetClick(assetURL: string): void {
    if (this.selectedElement && this.selectedElement.type === 'image') {
      this.selectedElement.element_url = assetURL;
      this.redrawCanvas();
    }
  }

  onSave() {
    if (this.canvas) {
      const dpi = 300;
      const a4Width = 8.27;  // in inches
      const a4Height = 11.69; // in inches
      const pdfWidth = a4Width * dpi;  // 2480px
      const pdfHeight = a4Height * dpi; // 3508px

      // Convert canvas to a data URL in high resolution
      const imgData = this.canvas.toDataURL('image/png', 1.0);

      // Create jsPDF instance (A4 format)
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [pdfWidth, pdfHeight],
      });

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      // Save the PDF
      pdf.save('canvas.pdf');
    }
  }
}
