import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TemplateConfig } from '@app/features/template-builder-tool/models/template-config';
import { environment } from '@env/environment';
import { Observable, tap } from 'rxjs';
import { DESIGN_STATUS, Design } from '../model/design';

@Injectable({
  providedIn: 'root'
})
export class DesignsService {
  http = inject(HttpClient);

  private createHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listDesigns(status: string = DESIGN_STATUS.RAW): Observable<Design[]> {
    const instanceUrl = localStorage.getItem('instance_url');
    const url = `${instanceUrl}${environment.designsEndPoint}`;
    const headers = this.createHeaders();

    const body = {
      method_code: '1',
      status: status + ', STANDARDIZED, IN_USE',
    };

    return this.http.post<Design[]>(url, body, { headers });
  }

  getDesign(jobCode: string): Observable<any> {
    const instanceUrl = localStorage.getItem('instance_url');
    const url = `${instanceUrl}${environment.designsEndPoint}`;
    const headers = this.createHeaders();

    const body = {
      method_code: '2',
      job_code: jobCode,
    };

    return this.http.post<any>(url, body, { headers }).pipe(tap((data) => {
      localStorage.setItem("designData$", JSON.stringify(data));
      return data;
    }));;  }

  updateDesign(templateConfig: Design): Observable<any> {
    const instanceUrl = localStorage.getItem('instance_url');
    const url = `${instanceUrl}${environment.designsEndPoint}`;
    const headers = this.createHeaders();
    templateConfig.method_code = '3';
    return this.http.post<any>(url, templateConfig, { headers });
  }

}
