import { Component, EventEmitter, HostListener, inject, OnInit, Output } from '@angular/core';
import { Router, RouterLink, RouterLinkActive} from '@angular/router';
import { INavButtons } from 'src/app/shared/models';
import { navButtons } from 'src/app/shared/constants';
import { CommonModule } from '@angular/common';
import { AuthService } from '@app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SignOutAlertComponent } from '@app/shared/sign-out/sign-out-alert/sign-out-alert.component';
import { NavBarComponent } from "../../../template-builder-tool/components/nav-bar/nav-bar.component";
import { JwtService } from '@app/core/services/jwt.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, CommonModule, NavBarComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
readonly dialog = inject(MatDialog);
private readonly jwtService = inject(JwtService);

@Output() searchTextChange = new EventEmitter<string>();
  navButtons: INavButtons[] = [];
  searchText = '';
  username: string = '';
  userInitials: string = '';
  firstLine: string='';
  secondLine: string='';
  isDropdownVisible: boolean = false;
   public isAuthor: boolean = false;
  public isApprover: boolean = false;
    isHeaderVisible: boolean = true;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.isHeaderVisible = !this.router.url.includes('standardize-designs');
    this.router.events.subscribe(() => {
      this.isHeaderVisible = !this.router.url.includes('standardize-designs');
    });
     this.isAuthor = this.authService.isTemplateAuthor();
     this.isApprover = this.authService.isTemplateApprover();
    this.loadNavButtons();
    this.setUserProfile();
  }
  get currentUrl(): string {
    return this.router.url;
  }
  openDashboard(){
    this.router.navigate(['/dashboard']); 
  }
  loadNavButtons() {
    if (this.authService.isTemplateApprover()) {
      this.navButtons = navButtons.filter(button => button.route === 'templates');
    } else if (this.authService.isTemplateAuthor()) {
      this.navButtons = navButtons; 
    }
  }

  setUserProfile() {
    const email = this.jwtService.getUserEmail();
    const [emailFirstPart] = email.split('.');
    const emailLastPart = email.substring(email.indexOf('.') + 1, email.indexOf('@'));

    const fullName = `${this.capitalize(emailFirstPart)} ${this.capitalize(emailLastPart)}`;
    
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    this.username = fullName;

    this.userInitials = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    if (this.username.length > 18) {
      const nearestSpaceIndex = this.username.lastIndexOf(' ', 18);
      if (nearestSpaceIndex > 0) {
        this.firstLine = this.username.substring(0, nearestSpaceIndex);
        this.secondLine = this.username.substring(nearestSpaceIndex + 1);
      } else {
        this.firstLine = this.username.substring(0, 18);
        this.secondLine = this.username.substring(18);
      }
    } else {
      this.firstLine = this.username;
      this.secondLine = '';
    }
  }

  capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
  
  trackById(index: number, item: INavButtons) {
    return item.id;
  }

  
  toggleDropdown() {
    console.log("sign-out");
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.profile-container')) {
      this.isDropdownVisible = false;
    }
  }

  signOut() {
    this.dialog.open(SignOutAlertComponent, {
      width: '350px'
    });
  }

  onSearchInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.searchText = target.value.toLowerCase();
    console.log('Header Search Input:', this.searchText);
    this.searchTextChange.emit(this.searchText); 
  }

}
