<div class="left-nav">
  <div class="scrollable-section">
    <div class="file-list">
      @for (image of availableImages; track image.id) {
      <div class="file-item">
        <img [src]="image.designUrl" alt="Image" draggable="true" (dragstart)="onDragStart($event, image)" width="150"
          height="100" tabindex="0" />
      </div>
      }
    </div>
  </div>
</div>