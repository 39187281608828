import { Component, inject, OnInit } from '@angular/core';
import { VerifyTokenService } from './verify-token.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ICognitoAuth } from './verify-token.model';
import { AwsSignerService } from '../services/aws-signer.service';
import { AuthService } from '../services/auth.service';
import { JwtService } from '../services/jwt.service';

@Component({
  selector: 'app-verify-token',
  standalone: true,
  imports: [],
  templateUrl: './verify-token.component.html',
  styleUrl: './verify-token.component.scss',
})
export class VerifyTokenComponent implements OnInit {
  ngVerifyTokenService = inject(VerifyTokenService);
  authService = inject(AuthService);
  awsSignerService = inject(AwsSignerService);
  router = inject(Router);
  jwtService = inject(JwtService);

  verifyingToken = 'Verifying user, please wait...';
  loggingIn = 'logging in, please wait...';
  authSuccess = 'logging in successfully, please wait...';

  message = '';

  // private lambdaFunctionUrl =
  //   'https://pljpxyka7dvcxjsjn7usw7qlva0vpgpm.lambda-url.ap-southeast-1.on.aws';
  private lambdaFunctionUrl =
    'https://72rmczvinwpgv7744xtme6sn2a0jnxso.lambda-url.us-east-1.on.aws';
  private accessToken = ''; // Obtained from Cognito

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const authCode = params['code'];
      if (authCode) {
        this.message = this.verifyingToken;
        this.ngVerifyTokenService.getAccessToken(authCode).subscribe(
          (data: ICognitoAuth) => {
            console.log(data.access_token);
            // TODO: Cookie encoding
            localStorage.setItem('idp_access_token', data.access_token);
            localStorage.setItem('idp_id_token', data.id_token);
            this.accessToken = data.id_token;
            this.invokeLambda();
          },
          (error) => {
            console.error('Error getting access token:', error);
            this.message = 'Error getting access token';
          }
        );
      }
    });
  }

  logout() {
    // this.ngVerifyTokenService.logout();
  }

  async invokeLambda() {
    this.ngVerifyTokenService.getSFAuthToken().subscribe((data: any) => {
      console.log(data);
      if (data.statusCode === 200) {
        const { access_token, instance_url } = data.body;
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('instance_url', instance_url);
      }
        this.message = this.authSuccess;
        this.getUserRole();
    }, err => {
      console.log(err);
      this.message = 'Error invoking Lambda';
    });  
  }

  getUserRole() {
    const user = this.jwtService.getUserEmail();
    // const user = 'appana.rama.lakshmi.nikhita@merck.com';
    this.authService.getUserRoleForTBT(user).subscribe(data => {
      if (data?.done) {
        const userRecords = data.records;
        if (userRecords?.length) {
          const role = userRecords[0].MSD_CORE_User_Role__c;
          this.authService.setUserRole(role);
          this.ngVerifyTokenService.setUserRole(role);
          this.router.navigate(['/dashboard']);
        } else {
          this.message = `User: ${user} is not mapped any role. User should be Author or Approver to proceed. Try contacting your administrator and re-login to the TBT.`
        }
        
      }
    }, error => {
      // TOD: Remove this once the API is up:
      this.ngVerifyTokenService.setUserRole('Author');
      this.router.navigate(['/dashboard']);
      console.error('Error while getting the user role: ', error);
    });
  }

}
