import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { endpoints } from '../constants/auth.constants';
import { AuthService } from '@app/core/services/auth.service';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);

  const isSalesforceTokenRefresh =
    req.url.includes(endpoints.designs) ||
    req.url.includes(endpoints.templates) ||
    req.url.includes(endpoints.query);

  const isAPIGatewayEndpoint = req.url.includes(authService.getAWSUrl());

  if (isSalesforceTokenRefresh) {
    const authToken = localStorage.getItem('access_token');

    req = authToken
      ? req.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        })
      : req;
  }

  if (isAPIGatewayEndpoint) {
    const idp = localStorage.getItem('idp');
    let auth = localStorage.getItem('idp_access_token');
    if (idp && idp === 'SF') {
      auth = localStorage.getItem('authToken');
    }

    req = req.clone({
      setHeaders: {
        Authorization: `${auth}`,
        idp: idp ?? 'cognito',
      },
    });

    if (idp && idp === 'SF') {
      req = req.clone({
        setHeaders: {
          'X-Merck-APIKey': 'yfPEnEbmAQzNPAoQdMJUrIRieVTuDDhw',
        },  
      });
    }
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      console.error('HTTP Error:', error);
      if (error) {
        switch (error.status) {
          case 401:
            if (isSalesforceTokenRefresh) {
              return handleSF401(authService, req, next);
            }
            break;
          default:
            throw error;
        }
      }
      throw error;
    })
  );
}

function handleSF401(
  authService: AuthService,
  req: HttpRequest<unknown>,
  next: (req: HttpRequest<unknown>) => Observable<HttpEvent<unknown>>
) {
  return authService.getNewToken().pipe(
    switchMap((newToken) => {
      const retry = req.clone({
        setHeaders: {
          Authorization: `Bearer ${newToken}`,
        },
      });
      return next(retry);
    })
  );
}
