import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { CanvasEditorService } from '../../services/canvas-editor.service';
import { Subscription } from 'rxjs';
import { CanvasElement } from '../../models/canvas-element';
import { CanvasElementConfig, MappingElements } from '../../models/template-config';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplateService } from '@app/shared/services/template.service';
import { IElementConfig } from '../../models/config-mapping.model';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-mappings',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mappings.component.html',
  styleUrl: './mappings.component.scss',
})
export class MappingsComponent implements OnInit, OnDestroy {
  private moduleAssetsSubscription!: Subscription;
  private elementSelectSubscription!: Subscription;

  @Output() imageSelected = new EventEmitter<CanvasElementConfig>();
  @Input() selectedElement: CanvasElementConfig | null = null;
  @Input() selectedModule: string | null = null;
  @Input() isTestTemplate: boolean = false; // New input property to check if it's in TestTemplateComponent
  @Input() templateData: { module_configuration?: IElementConfig[] } = {};


  modules: any[] = [];
  filteredModules: any[] = [];
  templateId: string | null = "";
  jobCode: string | null | undefined;

  constructor(private canvasEditorService: CanvasEditorService, private templateService: TemplateService, private router: Router, private route: ActivatedRoute, private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.subscribeToSelectedElement();
    this.route.paramMap.subscribe(params => {
      this.templateId = params.get('templateId');
      this.jobCode = params.get('jobCode');
      if (this.templateId) {
        this.fetchTemplateData(this.templateId);
      } else {
        console.error('Template ID not found in route parameters');
      }
    });

    this.loadDesigns(this.jobCode || '');
  }

  fetchTemplateData(templateId: string) {
    this.spinnerService.show();
    this.templateService.getTemplateById(templateId).subscribe(
      (response) => {
        this.templateData = response;
        if (this.templateData && this.templateData.module_configuration) {
          this.modules = this.templateData.module_configuration.map((moduleConfig) => ({
            id: moduleConfig.id,
            name: moduleConfig.label,
            assets: moduleConfig.module ? moduleConfig.module.assets : []
          }));
          this.filterModules();
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          console.error('module_configuration not found in templateData');
        }
      },
      (error) => {
        console.error('Error fetching template data:', error);
      }
    );
  }
  loadDesigns(jobCode: string) {
    this.spinnerService.show();
    this.templateService.getDesigns(jobCode).subscribe((response: any) => {
      const urls = response['presigned-urls'];
      if (!urls) {
        return;
      }
      Object.keys(urls).forEach((key) => {
        const fileNameFromDesigns = key.split('/').pop()?.toLowerCase();
        this.modules.forEach((module) => {
          if (module && Array.isArray(module.assets)) {
            const matchingAssetIndex = module.assets.findIndex((asset: string) => {
              const fileNameFromAssets = asset.split('/').pop()?.toLowerCase();
              return fileNameFromAssets === fileNameFromDesigns;
            });

            if (matchingAssetIndex !== -1) {
              const designUrl = urls[key];
              module.assets[matchingAssetIndex] = designUrl;
            } else {
              // console.log(`No matching asset found for file: ${fileNameFromDesigns} in module: ${module.name}`);
            }
            this.spinnerService.hide();
          } else {
            console.error('No assets found in module:', module);
            this.spinnerService.hide();
          }
        });
      });
      console.log('Updated modules after loading designs:', this.modules);
    });
  }

  isEmptyModules(): boolean {
    return this.modules.length === 0 || this.modules.every(module => !module || !module.name || !module.assets || module.assets.length === 0);
  }

  hasValidModules(): boolean {
    return this.modules.length > 0 && this.modules.some(module => module && module.name && module.assets && module.assets.length > 0);
  }


  isSelectedModule(moduleName: string): boolean {
    return this.selectedModule === moduleName;
  }

  onModuleCheckboxChange({id, name}: any) {
    if (!this.isTestTemplate) { // Only handle checkbox changes if not in TestTemplateComponent
      if (this.selectedModule === name) {
        this.selectedModule = null;
        this.canvasEditorService.updateSelectedModules([], id, name);
      } else {
        this.selectedModule = name;
        this.canvasEditorService.updateSelectedModules([name], id, name);
      }
    }
  }

  onAssetClick(assetURL: string): void {
    const asset: CanvasElementConfig = {
      id: '', // Ensure you set a valid id if needed
      section_id: '',
      type: 'image',
      x: 0,
      y: 0,
      file: undefined,
      element_name: '',
      element_url: assetURL,
      width: 100, // Set appropriate width
      height: 100, // Set appropriate height
      customizable: true,
      is_deleted: false
    };
    this.imageSelected.emit(asset);
  }

  subscribeToSelectedElement() {
    this.elementSelectSubscription = this.canvasEditorService.getElementSelectedObservable()
      .subscribe((element: CanvasElement | null) => {
        if (element) {
          const mappings = this.canvasEditorService.getTemplateConfig.mappings;
          if (mappings && Array.isArray(mappings)) {
            const selectedElementMappings = mappings.find((x: MappingElements) => x.zone_id === element.section_id);
            this.selectedModule = selectedElementMappings ? selectedElementMappings.module_name : null;
          } else {
            console.error('Mappings is not an array:', mappings);
            this.selectedModule = null;
          }
        }
      });
  }
  ngOnChanges(): void {
    this.filterModules();
  }
  filterModules(): void {
    if (this.selectedModule) {
      this.filteredModules = this.modules.filter(module => module.name === this.selectedModule);
    } else {
      this.filteredModules = this.modules;
      console.log('No module selected, displaying all modules');
    }
  }
  ngOnDestroy() {
    this.moduleAssetsSubscription?.unsubscribe();
    this.elementSelectSubscription?.unsubscribe();
  }
}
