<div class="editor-container">
  <div>
    <div class="control-panel">
      <!-- <app-spinner></app-spinner> -->
      <div class="canvas">
        <div (resize)="initializeCanvasDimensions()" (drop)="onFileDrop($event)" id="canvas-container"
          class="canvas-container">
          <canvas #canvas id="editorCanvas" [width]="canvasProperties.canvasWidth" [height]="canvasProperties.canvasHeight"
            (dragover)="onDragOver($event)" (mousemove)="onMouseMove($event)" (mouseup)="onMouseUp($event)"
            ></canvas>
          <div class="canvas-controls">
            <button (click)="previousPage()">Previous</button>
            <div class="page-buttons">
              @for (page of canvasPages; track $index; let i = $index) {
              <button [ngClass]="{ active: currentPageIndex === i }" (click)="selectPage(i)">
                {{ i + 1 }}
              </button>
              }
            </div>
            <button (click)="nextPage()">Next</button>
          </div>
        </div>

        <div class="main-container1">
          <!-- first div -->
          <div class="button-container1">
            <div class="button" (click)="addPage()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.2307 2.7692C10.3384 -0.0923375 5.66151 -0.0923375 2.7692 2.7692C-0.0923378 5.66151 -0.0923378 10.3384 2.7692 13.2307C5.66151 16.123 10.3384 16.123 13.2 13.2307C16.0923 10.3384 16.0923 5.66151 13.2307 2.7692ZM12.9231 8.61557C12.9231 8.80019 12.8 8.92326 12.6154 8.92326H9.23076C9.07691 8.92326 8.92306 9.07711 8.92306 9.23096V12.6156C8.92306 12.7694 8.76922 12.9233 8.61537 12.9233H7.3846C7.19999 12.9233 7.07691 12.8002 7.07691 12.6156V9.23096C7.07691 9.04634 6.95383 8.92326 6.76922 8.92326H3.3846C3.19999 8.92326 3.07691 8.80019 3.07691 8.61557V7.3848C3.07691 7.23096 3.23076 7.07711 3.3846 7.07711H6.76922C6.95383 7.07711 7.07691 6.95403 7.07691 6.76942V3.3848C7.07691 3.23096 7.23076 3.07711 7.3846 3.07711H8.61537C8.76922 3.07711 8.92306 3.20019 8.92306 3.3848V6.76942C8.92306 6.95403 9.04614 7.07711 9.23076 7.07711H12.6154C12.7692 7.07711 12.9231 7.23096 12.9231 7.3848V8.61557Z"
                  fill="#747474" />
              </svg>
            </div>
            <div class="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.5383 0.615234H5.53832C4.86139 0.615234 4.30755 1.16908 4.30755 1.84601V2.46139H11.6922C12.3691 2.46139 12.9229 3.01524 12.9229 3.69216V12.3076H13.5383C14.2153 12.3076 14.7691 11.7537 14.7691 11.0768V1.84601C14.7691 1.16908 14.2153 0.615234 13.5383 0.615234ZM11.6923 4.92294C11.6923 4.24601 11.1384 3.69217 10.4615 3.69217H2.46148C1.78456 3.69217 1.23071 4.24601 1.23071 4.92294V14.1537C1.23071 14.8306 1.78456 15.3845 2.46148 15.3845H10.4615C11.1384 15.3845 11.6923 14.8306 11.6923 14.1537V4.92294ZM6.15382 7.0769C6.15382 7.26151 6.03074 7.38459 5.84613 7.38459H3.38458C3.19997 7.38459 3.07689 7.26151 3.07689 7.0769V6.46151C3.07689 6.2769 3.19997 6.15382 3.38458 6.15382H5.84613C6.03074 6.15382 6.15382 6.2769 6.15382 6.46151V7.0769ZM8.61536 11.9998C8.61536 12.1844 8.49228 12.3075 8.30767 12.3075H3.38458C3.19997 12.3075 3.07689 12.1844 3.07689 11.9998V11.3844C3.07689 11.1998 3.19997 11.0767 3.38458 11.0767H8.30767C8.49228 11.0767 8.61536 11.1998 8.61536 11.3844V11.9998ZM9.84613 9.5383C9.84613 9.72292 9.72305 9.846 9.53844 9.846H3.38458C3.19997 9.846 3.07689 9.72292 3.07689 9.5383V8.92292C3.07689 8.7383 3.19997 8.61523 3.38458 8.61523H9.53844C9.72305 8.61523 9.84613 8.7383 9.84613 8.92292V9.5383Z"
                  fill="#747474" />
              </svg>
            </div>
            <div class="button" (click)="removePage()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.0001 3.07675H10.154V1.84599C10.154 1.16908 9.60011 0.615234 8.92319 0.615234H7.07704C6.40011 0.615234 5.84627 1.16908 5.84627 1.84599V3.07675H2.00011C1.75396 3.07675 1.53857 3.29214 1.53857 3.53829V4.46136C1.53857 4.70751 1.75396 4.92289 2.00011 4.92289H14.0001C14.2463 4.92289 14.4617 4.70751 14.4617 4.46136V3.53829C14.4617 3.29214 14.2463 3.07675 14.0001 3.07675ZM7.0771 2.15337C7.0771 1.96875 7.20018 1.84568 7.3848 1.84568H8.61556C8.80018 1.84568 8.92326 1.96875 8.92326 2.15337V3.07644H7.0771V2.15337ZM12.7692 6.15377H3.23076C2.98461 6.15377 2.76923 6.36915 2.76923 6.61531V13.846C2.76923 14.7076 3.44615 15.3845 4.30769 15.3845H11.6923C12.5538 15.3845 13.2308 14.7076 13.2308 13.846V6.61531C13.2308 6.36915 13.0154 6.15377 12.7692 6.15377ZM7.07702 12.9229C7.07702 13.1075 6.95395 13.2306 6.76933 13.2306H6.15394C5.96933 13.2306 5.84625 13.1075 5.84625 12.9229V8.61523C5.84625 8.43062 5.96933 8.30754 6.15394 8.30754H6.76933C6.95395 8.30754 7.07702 8.43062 7.07702 8.61523V12.9229ZM10.1538 12.9229C10.1538 13.1075 10.0308 13.2306 9.84616 13.2306H9.23077C9.04616 13.2306 8.92308 13.1075 8.92308 12.9229V8.61523C8.92308 8.43062 9.04616 8.30754 9.23077 8.30754H9.84616C10.0308 8.30754 10.1538 8.43062 10.1538 8.61523V12.9229Z"
                  fill="#747474" />
              </svg>
            </div>
          </div>

          <!-- second div -->
          <div class="button-container2">
            <div class="button" (click)="redo()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M8.00011 2.43082V0.80005C8.00011 0.646204 8.18473 0.553896 8.33857 0.646204L11.5078 3.26159C11.6309 3.3539 11.6309 3.53851 11.5078 3.63082L8.33857 6.21543C8.2155 6.33851 8.00011 6.21544 8.00011 6.09236V4.43082C5.53857 4.43082 3.5078 6.43082 3.5078 8.92313C3.5078 11.4154 5.53857 13.3847 8.00011 13.3847C10.4617 13.3847 12.4924 11.3847 12.4924 8.89236C12.4924 8.18467 12.3386 7.50774 12.0309 6.89236C12.0309 6.89236 12.0309 6.86159 12.0001 6.83082C11.9386 6.70774 11.877 6.5539 11.8155 6.46159C11.7232 6.30774 11.6309 6.06159 11.8155 5.90774C12.0001 5.7539 12.7693 5.10774 12.8309 5.07697C12.8924 5.0462 13.1078 4.83082 13.3232 5.13851C13.4463 5.32313 13.6001 5.60005 13.7232 5.81543C13.7232 5.8462 13.754 5.8462 13.754 5.87697C13.8155 5.96928 13.8463 6.06159 13.877 6.09236C14.277 6.92313 14.4924 7.87697 14.4924 8.89236C14.4924 12.4616 11.6001 15.3539 8.03088 15.3539C4.46165 15.3539 1.53857 12.4924 1.53857 8.92313C1.53857 5.3539 4.43088 2.43082 8.00011 2.43082Z"
                  fill="#747474" />
              </svg>
            </div>
            <div class="button" (click)="undo()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M8.00012 2.43082V0.80005C8.00012 0.646204 7.81551 0.553896 7.66166 0.646204L4.49243 3.26159C4.36935 3.3539 4.36935 3.53851 4.49243 3.63082L7.66166 6.21543C7.78474 6.33851 8.00012 6.21544 8.00012 6.09236V4.43082C10.4617 4.43082 12.4924 6.43082 12.4924 8.92313C12.4924 11.4154 10.4617 13.3847 8.00012 13.3847C5.53858 13.3847 3.50781 11.3847 3.50781 8.89236C3.50781 8.18467 3.66166 7.50774 3.96935 6.89236C3.96935 6.89236 3.96935 6.86159 4.00012 6.83082C4.06166 6.70774 4.1232 6.5539 4.18474 6.46159C4.27704 6.30774 4.36935 6.06159 4.18474 5.90774C4.00012 5.7539 3.23089 5.10774 3.16935 5.07697C3.10781 5.0462 2.89243 4.83082 2.67704 5.13851C2.55397 5.32313 2.40012 5.60005 2.27704 5.81543C2.27704 5.8462 2.24627 5.8462 2.24627 5.87697C2.18474 5.96928 2.15397 6.06159 2.1232 6.09236C1.7232 6.92313 1.50781 7.87697 1.50781 8.89236C1.50781 12.4616 4.40012 15.3539 7.96935 15.3539C11.5386 15.3539 14.4309 12.4616 14.4309 8.89236C14.4309 5.32313 11.5694 2.43082 8.00012 2.43082Z"
                  fill="#747474" />
              </svg>
            </div>
          </div>

          <!-- third div -->
          <div class="button-container3">
            <div class="button" (click)="zoomOut()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.53856 5.84531H7.69241V3.99916C7.69241 3.81454 7.56933 3.69147 7.38471 3.69147H6.15394C5.96933 3.69147 5.84625 3.81454 5.84625 3.99916V5.84531H4.0001C3.81548 5.84531 3.69241 5.96839 3.69241 6.15301V7.38378C3.69241 7.56839 3.81548 7.69147 4.0001 7.69147H5.84625V9.53762C5.84625 9.72224 5.96933 9.84531 6.15394 9.84531H7.38471C7.56933 9.84531 7.69241 9.72224 7.69241 9.53762V7.69147H9.53856C9.72318 7.69147 9.84625 7.56839 9.84625 7.38378V6.15301C9.84625 5.96839 9.72318 5.84531 9.53856 5.84531ZM15.2616 13.9075L11.7232 10.3998C12.4616 9.38447 12.9232 8.12293 12.9232 6.76908C12.9232 3.38447 10.1539 0.615234 6.76932 0.615234C3.38471 0.615234 0.615479 3.38447 0.615479 6.76908C0.615479 10.1537 3.38471 12.9229 6.76932 12.9229C8.12317 12.9229 9.38471 12.4614 10.4001 11.7229L13.9386 15.2614C14.1232 15.446 14.4001 15.446 14.5847 15.2614L15.2309 14.6152C15.4155 14.4306 15.4155 14.1229 15.2616 13.9075ZM6.76925 11.0764C4.40002 11.0764 2.46156 9.13794 2.46156 6.76871C2.46156 4.39948 4.40002 2.46101 6.76925 2.46101C9.13848 2.46101 11.0769 4.39948 11.0769 6.76871C11.0769 9.13794 9.13848 11.0764 6.76925 11.0764Z"
                  fill="#747474" />
              </svg>
            </div>
            <div class="button button-text" (click)="reset()">100%</div>
            <div class="button" (click)="zoomIn()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.2616 13.9383L11.7232 10.3998C12.4616 9.38447 12.9232 8.12293 12.9232 6.76908C12.9232 3.38447 10.1539 0.615234 6.76932 0.615234C3.38471 0.615234 0.615479 3.38447 0.615479 6.76908C0.615479 10.1537 3.38471 12.9229 6.76932 12.9229C8.12317 12.9229 9.38471 12.4614 10.4001 11.7229L13.9386 15.2614C14.1232 15.446 14.4001 15.446 14.5847 15.2614L15.2309 14.6152C15.4155 14.4306 15.4155 14.1229 15.2616 13.9383ZM6.76925 11.0764C4.40002 11.0764 2.46156 9.13794 2.46156 6.76871C2.46156 4.39948 4.40002 2.46101 6.76925 2.46101C9.13848 2.46101 11.0769 4.39948 11.0769 6.76871C11.0769 9.13794 9.13848 11.0764 6.76925 11.0764ZM4.0001 5.84561C3.81549 5.84561 3.69241 5.96869 3.69241 6.1533V7.38407C3.69241 7.56869 3.81549 7.69176 4.0001 7.69176H9.53865C9.72327 7.69176 9.84634 7.56869 9.84634 7.38407V6.1533C9.84634 5.96869 9.72327 5.84561 9.53865 5.84561H4.0001Z"
                  fill="#747474" />
              </svg>
            </div>
          </div>

<!--          <button class="btn btn-light" (click)="addText()" title="Add Text">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fonts"-->
<!--              viewBox="0 0 16 16">-->
<!--              <path-->
<!--                d="M12.258 3h-8.51l-.083 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062 2.434.301 2.693 1.846h.479z" />-->
<!--            </svg>-->
<!--          </button>-->

        </div>
      </div>
    </div>
  </div>
</div>
<app-preview-template></app-preview-template>
