import { IElementConfig } from "./config-mapping.model";

export class TemplateConfig {
    method_code = '2';
    job_code = '';
    user_id = '';
    template_id = '';
    design_name = '';
    status = '';
    comments?: string;
    canvas_configuration: CanvasElementConfig[] = [];
    mappings: MappingElements[] = [];
    module_configuration?: IElementConfig[] = [];
  }
  
  export interface CanvasElementConfig {
    id?: string;
    type: 'image' | 'text';
    x: number;
    y: number;
    targetX?:number;
    targetY?:number;
    file?: HTMLImageElement;
    text?:string;
    element_name: string;
    element_url: string;
    width: number;
    height: number;
    customizable: boolean | undefined;
    page_number?: number;
    color?: string;
    fontFamily?: string;
    fontSize?: number;
    backgroundColor?: string | null;
    is_deleted:boolean;
    is_dirty?:boolean;
    section_id: string;
  }
  
  export interface MappingElements {
    zone_id: string;
    module_id: string;
    module_name: string;
    is_deleted?:boolean;
    is_dirty?:boolean
  }
  
  export interface getTemplatebyID {
    templateId: string;
    userId: string;
  }
  
  export interface getTemplatebyIDResponse {
    user_id: string;
    thumbnail: string | null; 
    template_language: string | null;
    template_id: string;
    status: string | null;
    mappings: any[];
    job_code: string;
    design_name: string | null;
    comments: string | null; 
    canvas_configuration: any[];
  }
  
  