import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IElementConfig, IOptions } from '@app/features/template-builder-tool/models/config-mapping.model';
import { MappingElements } from '@app/features/template-builder-tool/models/template-config';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() module_configuration!: IElementConfig[];
  @Input() templateType!: string;
  dropDownConfig: IElementConfig | undefined;
  selectedValue!: IOptions;
  @Output() selectedConfigDropDown = new EventEmitter<IOptions>();
  @Output() selectedConfig = new EventEmitter<IOptions[]>();
  @Output() onDropdownLoaded = new EventEmitter<(value: any) => void>();
  @Input() mappings: MappingElements[] = [];
  selectedElement: any;

  constructor() { }

  ngOnInit() {
    this.getSelectedElement();
  }

  ngOnChanges() {
    if (this.templateType.toLowerCase() == 'picklist') {
      this.setSelectedValue();
    }
  }

  onSelectionChange() {
    if (this.templateType.toLowerCase() == 'picklist') {
      this.selectedConfigDropDown.emit(this.selectedValue); //Draw in separate pages for demographic type/picklist
    } else {
      let selectedValueArray = [];
      this.selectedValue.key = this.selectedValue.thumbnail;
      selectedValueArray.push(this.selectedValue);
      this.selectedConfig.emit(selectedValueArray); //Draw in each dropzone similiar to listview
    }
  }

  setSelectedValue() {
    if (this.templateType.toLowerCase() == 'picklist') {
      this.dropDownConfig = this.module_configuration?.filter((item) => item.type.toLocaleLowerCase() == "dropdown")[0];
      if (this.dropDownConfig?.options && this.dropDownConfig?.options[0]) {
        const moduleConfigOption = this.dropDownConfig.options[0];
        this.selectedValue = moduleConfigOption || "";
        this.onSelectionChange();
      }
    } else {
      const dropDownConfig = this.module_configuration?.filter((item) => item.type.toLocaleLowerCase() == "dropdown");

      const selectedZoneMappedModuleId = this.mappings?.find((mapping) => mapping.zone_id === this.selectedElement?.section_id);
      if (selectedZoneMappedModuleId) {
        this.dropDownConfig = dropDownConfig.find((element) => element.id === selectedZoneMappedModuleId.module_id);
      }
      
    }
  }

  getSelectedElement() {
    this.onDropdownLoaded.emit((value: any) => {
      const {elements, elementIndex} = value;
      this.selectedElement = elements[elementIndex];
      this.dropDownConfig?.options?.forEach((element: any) => {
        if (element.thumbnail === elements[elementIndex].element_url) {
          this.selectedValue = element;
        }
      })
      this.setSelectedValue();
    });
  }

}