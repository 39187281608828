<div class="module-container">
  <div class="head">
    <div class="title">{{ module_configuration.label | titlecase }}</div>
    <!-- <div class="subtext">Select any of {{ module_configuration.noOfSelection }}</div> -->
    <div class="subtext">{{module_configuration.helpText }}</div>  
  </div>
  <div cdkDropList (cdkDropListDropped)="rearrange($event)">
    <mat-selection-list #moduleconfiguration
      (selectionChange)="onSelectionChange($event)">
      @for (option of module_configuration.options; track option.id) {
        <div class="list-item-container" cdkDrag>
          <span cdkDragHandle class="drag-handle">&#x2630;</span>
          <div class="vertical-line"></div>
          <mat-list-option [value]="option" togglePosition="before" [selected]="option?.id === this.selectedOptionInList?.id"
            [disabled]="isOptionDisabled(option)">
            <img width="19.46" height="32" [src]="option.preSignedUrl" appHoverPreview="{{ option.preSignedUrl }}" />
            <span class="option">{{ option.label | titlecase }}</span>
          </mat-list-option>
        </div>
      }
    </mat-selection-list>
  </div>
</div>

@if (previewImage) {
  <div class="image-preview-overlay">
    <img [src]="previewImage" class="preview-image" />
  </div>
}