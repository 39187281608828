import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageCacheS3Service {
  private imageCache: Record<string, HTMLImageElement> = {};

  preloadImages(imageUrls: string[]): Promise<void[]> {
    const promises = imageUrls.map(url => {
      return new Promise<void>((resolve, reject) => {
        // Check if the image is already cached
        if (this.imageCache[url]) {
          resolve();  // Resolve immediately if the image is cached
        } else {
          // Fetch the image as a Blob from the S3 URL
          fetch(url, { mode: 'cors' })  // Ensure CORS is enabled
            .then(response => {
              if (!response.ok) {
                throw new Error('Failed to fetch image');
              }
              return response.blob();  // Get image as Blob
            })
            .then(blob => {
              // Create a local object URL for the Blob
              const objectUrl = URL.createObjectURL(blob);
              const img = new Image();
              img.crossOrigin = 'anonymous';  // Set crossOrigin to handle CORS
              img.src = objectUrl;  // Use object URL as source

              img.onload = () => {
                console.log('Image loaded and cached:', url);  // Debug log
                this.imageCache[url] = img;  // Store the image in cache
                resolve();
              };

              img.onerror = (error) => {
                console.error('Error loading image from blob:', url, error);  // Handle error
                reject(error);
              };
            })
            .catch(error => {
              console.error('Error fetching image as blob:', url, error);  // Fetch error handling
              reject(error);
            });
        }
      });
    });

    return Promise.all(promises);  // Return a promise when all images are loaded
  }

  // Method to get the image from the cache
  getImage(url: string): HTMLImageElement | null {
    const img = this.imageCache[url];
    if (!img) {
      console.warn(`Image not found in cache for URL: ${url}`);  // Debug log
    }
    return img || null;
  }
  getCacheImages() {
    return this.imageCache;
  }

  isImageCached(url: string): boolean {
    return !!this.imageCache[url];
  }

  clearAllCache(): void {
    Object.keys(this.imageCache).forEach(url => {
      const img = this.imageCache[url];
      if (img) {
        const objectUrl = img.src;  // Assuming the src property contains the object URL
        URL.revokeObjectURL(objectUrl);  // Release memory for the object URL
      }
    });
    this.imageCache = {};  // Clear the image cache
  }

}
