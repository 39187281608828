<div class="dialog-container"> 
  <div class="language-mapper-panel">
    <strong>Language Mapper Names</strong>
    <ul>
      <li *ngFor="let mapper of languageMappers" (click)="onSelectMapper(mapper)">
        {{ mapper.language_mapper_name }}
      </li>
    </ul>
  </div>

  <div class="middle-section">
    <button *ngIf="!selectedMapper" mat-icon-button class="add-button" (click)="toggleLanguageDropdown()">
      <mat-icon>add</mat-icon>
    </button>

    <mat-form-field appearance="fill" *ngIf="isDropdownVisible && !selectedMapper" class="combo-box">
      <mat-label>Combo Box - Select Language</mat-label>
      <input type="text"
             matInput
             [matAutocomplete]="auto"
             [(ngModel)]="selectedLanguage"
             (input)="filterLanguages()">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onLanguageChange()">
        <mat-option *ngFor="let language of filteredLanguages" [value]="language.template_name">
          {{ language.template_name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div *ngIf="selectedMapper || isDropdownVisible">
      <mat-form-field appearance="fill" class="languages-select">
        <mat-label>Languages</mat-label>
        <mat-select [(value)]="selectedLanguages" multiple>
          <mat-option *ngFor="let template of templateData" [value]="template.template_id">
            {{ template.template_name }} - {{ template.template_language || 'No Language' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="selectedMapper">
      <p><strong>Selected Mapper:</strong> {{ selectedMapper.language_mapper_name }}</p>
    </div>

    <div class="dialog-actions">
      <button mat-button class="save-button" (click)="onSave()">Save</button>
      <button mat-button class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</div>
