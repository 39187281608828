import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatListModule, MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { IElementConfig, IOptions } from '../../../models/config-mapping.model';
import { CommonModule } from '@angular/common';
import { TemplateService } from 'src/app/shared/services/template.service';
import { HoverPreviewDirective } from '../hover-preview.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CanvasEditorService } from '@app/features/template-builder-tool/services/canvas-editor.service';

@Component({
  selector: 'app-listview',
  standalone: true,
  imports: [MatListModule, CommonModule, DragDropModule, HoverPreviewDirective],
  templateUrl: './listview.component.html',
  styleUrl: './listview.component.scss'
})
export class ListviewComponent implements OnInit {
  @ViewChild('moduleconfiguration', { static: true })
  moduleconfiguration!: MatSelectionList;
  @Input()
  job_code!: string;
  @Input()
  module_configuration!: IElementConfig;
  @Output() selectedConfig = new EventEmitter<IOptions[]>();
  @Output() onListViewLoaded = new EventEmitter<(value: any) => void>();
  @Input() presignedurls: string[] = [];

  selectedOptions: IOptions[] = [];
  previewImage: string | null = null;
  selectedOptionInList: any;
  elements: any[] = [];
  selectedElement: any;

  constructor(private templateService: TemplateService, private canvasService: CanvasEditorService) { }
  ngOnInit(): void {
    this.selectOptionThumbnail();
    this.getSelectedElement();
  }

  onSelectionChange(event: MatSelectionListChange) {
    const selectedOptions = event.source.selectedOptions.selected;


    if (selectedOptions.length > 1) {
      selectedOptions[0].selected = false;
      this.selectedOptions = this.selectedOptions.filter(option => option.id !== selectedOptions[0].value.id);
    }


    if (selectedOptions.length > 0) {
      this.selectedOptions.push(selectedOptions[selectedOptions.length - 1].value);
      this.selectedOptionInList = selectedOptions[selectedOptions.length - 1].value;
      this.selectedConfig.emit(this.selectedOptions);
    } else {


      // this.selectedElement.element_url = this.selectedElement.placeholder;
      this.selectedConfig.emit([{key: this.selectedElement.placeholder}] as IOptions[]);
    }
    
  }


  isOptionDisabled(option: any) {

    const selectedOptions = this.moduleconfiguration.selectedOptions.selected;

    // console.log('SELECTED OPTIONS: ', selectedOptions);
    
    // if (!this.selectedOptionInList) {
    //   return false;
    // }

    const elementUrls = this.elements.map((element: any) => {
      if (this.selectedElement.id !== element.id){
       return element.element_url;
      }
    });

    if (elementUrls.includes(option.thumbnail)) {
      return true;
    } else {
      return false;
    }
    
    // if (id === this.selectedOptionInList.id) {
    //   return true;
    // } else {
    //   return false; 
    // }    
  }

  async selectOptionThumbnail() {
    Object.keys(this.presignedurls).forEach((key, index) => {
      this.module_configuration.options?.forEach((config: any) => {
        if (key == config?.thumbnail) {
          config.key = key;
          config.preSignedUrl = Object.values(this.presignedurls)[index];
        }
      });
    });
  }
  rearrange(event: any) {
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
    if (this.module_configuration?.options) {
      const item = this.module_configuration.options.splice(
        previousIndex,
        1
      )[0];
      this.module_configuration.options.splice(currentIndex, 0, item);
    }
  }
  showPreview(imageSrc: string) {
    this.previewImage = imageSrc;
  }
  hidePreview() {
    this.previewImage = null;
  }
  
  resetListView(value: any) {
    const {elements, elementIndex} = value;
    this.elements = elements;
    this.selectedElement = elements[elementIndex];
    this.selectedOptionInList = this.module_configuration?.options?.find(option => option.thumbnail === this.selectedElement.element_url);
  }

  getSelectedElement() {
    this.onListViewLoaded.emit((value: any) => {
      const {elements, elementIndex} = value;
      this.elements = elements;
      this.selectedElement = elements[elementIndex];
      this.selectedOptionInList = this.module_configuration?.options?.find(option => option.thumbnail === this.selectedElement.element_url);
    });
  }

}
