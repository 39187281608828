import { TreeNode, TreeNodeTypes, UploadFile } from "@app/features/dashboard/model/tree-node";
import { StaticFile } from "../store/models/folder-structure.model";

export const buildTreeFromS3 = (contents: any[]): TreeNode[] => {
    if (!contents) {
        return [];
    }
    return contents.map((record: any) => {
        const node: TreeNode = {
            name: record.name
        }
        if (record.contents && record.contents.length > 0) {
            node.children = buildTreeFromS3(record.contents);
        } else {
            node.draggable = true;
            node.link = record.path
        }
        return node;
    })
}


export const buildTreeFromSF = (sfResponse: any, job_id: string): TreeNode[] => {
    const output =
    {
        name: job_id ?? "Design",
        children: [
            { name: 'Thumbnail File - ' + (sfResponse.thumbnail ?? ""), droppable: true, link: sfResponse.thumbnail, upload: true, type: TreeNodeTypes.THUMBNAIL },
            {
                name: 'InDesign File',
                children: [{
                    name: "fonts",
                    children: sfResponse?.design_file[0]?.fonts.map( (link: string) => {
                        return {name: link, link, parent: TreeNodeTypes.FONT}
                    }),
                    droppable: true,
                    upload: true,
                    type: TreeNodeTypes.FONT,
                }, {
                    name: "links",
                    children: sfResponse?.design_file[0]?.links.map( (link: string) => {
                        return {name: link, link, parent: TreeNodeTypes.LINKS}
                    }),
                    droppable: true,
                    upload: true,
                    type: TreeNodeTypes.LINKS,
                }, {
                    name: "indd - "+(sfResponse?.design_file[0]?.indd ?? ""),
                    upload: true,
                    droppable: true,
                    parent: TreeNodeTypes.INDD,
                }, {
                    name: "idml- "+(sfResponse?.design_file[0]?.idml ?? ""),
                    upload: true,
                    droppable: true,
                    parent: TreeNodeTypes.IDML,
                }]
            },
            { name: 'Mapping Guidelines file - ' + (sfResponse.mapping_guidelines_file ?? ""), droppable: true, upload: true, link: sfResponse.mapping_guidelines_file, type: TreeNodeTypes.MAPPING_GUIDELINE_FILE },
            { name: 'Final Resource - ' + (sfResponse.combined_pdf ?? ""), link: sfResponse.combined_pdf, droppable: true, upload: true, type: TreeNodeTypes.COMBINED_PDF },
            {
                name: 'Static Files',
                children: buildStaticFiles(sfResponse.static_files),
                type: "static",
                upload: true,
                droppable: true,
            },
            {
                name: 'Module Assets',
                children: buildModuleAssets(sfResponse.Modules),
                addFolder: true
            },
        ]
    }
    return [output];
}

const buildStaticFiles = (static_files: StaticFile[]): TreeNode[] => {
    return static_files.map((file: StaticFile) => {
        return { id: file.section_id ?? file.id, name: file.name, parent: TreeNodeTypes.STATIC, link: file.url, properties: true }
    })
}

const buildModuleAssets = (sfModules: any[]): TreeNode[] => {
    const modules: TreeNode[] = [];
    let index = 0;
    sfModules.forEach(sfModule => {
        modules.push({
            name: sfModule.name, upload: true, droppable: true, index, type: TreeNodeTypes.MODULE, children: sfModule.assets.map((asset: string) => {
                return { name: asset, index, parent: TreeNodeTypes.MODULE, link: asset}
            })
        });
        index++;
    });
    return modules;
}

export const extractPathFromNode = (node: TreeNode, jobId: string, fileName: string,link?:string): UploadFile => {
    const name = [jobId];
    if (TreeNodeTypes.MODULE === node.type) {
        name.push("design/modules", node.name);
    }
    if (TreeNodeTypes.STATIC == node.type) {
        name.push("design/static_files");
    }
    name.push(fileName);
    // let returnObject;
    // if (link !== undefined) {
    //   returnObject = { path: link, name: fileName };
    // } else {
    const returnObject = { path: name.join('/'), name: fileName };
    // }

  return returnObject;
}

export const updateTree = (file: UploadFile, node: TreeNode, data: any, jobId: string) => {
    const fileId = uuidv4();
    switch(node.type) {
        case TreeNodeTypes.MODULE:
          if(node.index !=undefined) {
            data["Modules"][node.index].assets.push(file.path);
          }
          break;
        case TreeNodeTypes.STATIC:
          data.static_files.push({
            url: file.path,
            name: file.name,
            job_code: jobId,
            isPlaceholder: false,
            id: fileId,
            section_id: fileId,
          })
          break;
        case TreeNodeTypes.FONT:
          data.design_file[0].fonts.push(file.path);
          break;
        case TreeNodeTypes.LINKS:
          data.design_file[0].links.push(file.path);
          break;
        case TreeNodeTypes.INDD:
          data.design_file[0].indd = (file.path);
          break;
        case TreeNodeTypes.IDML:
          data.design_file[0].idml = (file.path);
          break;
        default:
          if(node.type)
            data[node.type] = file.path;
      }
      return data;
}

export const removeNodeFromTree = (data: any, node: TreeNode) => {
    switch(node.parent) {
        case TreeNodeTypes.MODULE:
          if(node.index != undefined){
            const index = data["Modules"][node.index].assets.indexOf(node.name);
            data["Modules"][node.index].assets.splice(index, 1);
          }
          break;
        case TreeNodeTypes.STATIC:
          const index = data.static_files.findIndex((obj: StaticFile) => {
            return (obj.name == node.name)
          });
          data.static_files.splice(index, 1);
          break;
        case TreeNodeTypes.FONT:
            const fontIndex = data.design_file[0].fonts.indexOf(node.name);
            data.design_file[0].fonts.splice(fontIndex, 1);
            break;
        case TreeNodeTypes.LINKS:
            const linkIndex = data.design_file[0].links.indexOf(node.name);
            data.design_file[0].links.splice(linkIndex, 1);
             break;
        case TreeNodeTypes.INDD:
            data.design_file[0].indd = "";
            break;
        case TreeNodeTypes.IDML:
            data.design_file[0].idml = "";
            break;
        default:
          if(node.type)
            data[node.type] = "";
          break;
      }
      return data;
}


export const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}
