import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CanvasElement } from '../models/canvas-element';
import { Observable, of, Subject } from 'rxjs';
import data from '../../../../assets/static-data/dm.json';
import { CanvasElementConfig, TemplateConfig } from '../models/template-config';
import { ElementConfig } from '../models/element-config';
import { HttpClient } from '@angular/common/http';
import { TemplateService } from '@app/shared/services/template.service';
import { reset, setZoneStatus, updateDimension } from '@app/shared/store/actions/properties.actions';
import { Store } from '@ngrx/store';
import { IElementConfig } from '../models/config-mapping.model';

@Injectable({
  providedIn: 'root',
})
export class CanvasEditorService {

  private setElementPropertiesSubject = new BehaviorSubject<CanvasElement | null>(null);
  private propertiesUpdateSubject = new Subject<CanvasElement>();
  private navBarEventSubject = new Subject<string>();
  private imageAddedSubject = new Subject<string>();
  private imageRemovedSubject = new Subject<string>();
  navBarEvent$ = this.navBarEventSubject.asObservable();
  private elementsChangedSubject = new Subject<CanvasElement[]>();
  private elementSelectedSubject = new Subject<CanvasElement | null>();
  private selectedModulesSubject = new BehaviorSubject<string[]>([]);
  private templateConfig: TemplateConfig = new TemplateConfig();
  private elementMoveSubject = new Subject<number>();
  selectedElement!: CanvasElement;
  private unsavedChangesSource = new BehaviorSubject<boolean>(false);
  unsavedChanges$ = this.unsavedChangesSource.asObservable();
  private selectedElementSource = new BehaviorSubject<CanvasElementConfig | null>(null);
  selectedElement$ = this.selectedElementSource.asObservable();
  // private _templateConfig: TemplateConfig = new TemplateConfig();
  private elements: ElementConfig[] = [];
  private commentsSubject = new BehaviorSubject<string>('');
  comments$ = this.commentsSubject.asObservable();

  constructor(private store: Store, private templateService: TemplateService, private http: HttpClient) {
    const savedConfig = localStorage.getItem('templateData$');
    if (savedConfig) {
      this.templateConfig = JSON.parse(savedConfig) as TemplateConfig;
    }
  }

  setElementProperties(element: CanvasElement | null): void {
      this.setElementPropertiesSubject.next(element);
      if (element) {
        console.log(element);
        this.selectedElement = element;
        this.elementSelectedSubject.next(element);
        const elementConfig: CanvasElementConfig = {
          type: element.type,
          x: element.x,
          y: element.y,
          targetX: element.targetX,
          targetY: element.targetY,
          text: element.text,
          element_name: element.element_name,
          element_url: element.element_url,
          width: element.width,
          height: element.height,
          customizable: element.customizable ?? false,
          page_number: element.page_number !== undefined ? element.page_number : 0,
          color: element.color,
          fontFamily: element.fontFamily,
          fontSize: element.fontSize,
          backgroundColor: element.backgroundColor,
          is_deleted:element.is_deleted,
          is_dirty:element.is_dirty,
          section_id: element.section_id,
        };
        if(element.id){
          elementConfig.id = element.id
        }
        const matchingConfigs = this.templateConfig.canvas_configuration.filter(
          config => config.section_id === elementConfig.section_id
        );
        
        if (matchingConfigs.length > 0) {
          this.templateConfig.canvas_configuration = this.templateConfig.canvas_configuration.map(config => {
            if (config.section_id === elementConfig.section_id) {
              return {
                ...config,
                ...elementConfig
              };
            }
            return config;
          });
        } else {
          this.templateConfig.canvas_configuration.push(elementConfig);
        }
        
        this.selectedElementSource.next(elementConfig);
        // this.store.dispatch(updateDimension({ width: element?.width, height: element?.height }))
        // this.store.dispatch(setZoneStatus({ value: element?.customizable ?? false }))
      } else {
  
        console.log("else element");
        this.selectedElementSource.next(null);
        //this.store.dispatch(reset())
      }
      this.setUnsavedChanges(true);
    }
  
    getElements(): ElementConfig[] {
      return this.elements;
    }
  
    updateElementDimensions(selectedElement: CanvasElement): void {
      this.propertiesUpdateSubject.next(selectedElement);
      this.setUnsavedChanges(true);
    }
  
    updateSaveInteraction(eventName: string) {
      this.navBarEventSubject.next(eventName);
      this.setUnsavedChanges(false);
    }
  
    notifyImageAdded(fileName: string) {
      this.imageAddedSubject.next(fileName);
      this.setUnsavedChanges(true);
    }
  
    notifyImageRemoved(fileName: string) {
      this.imageRemovedSubject.next(fileName);
      this.setUnsavedChanges(true);
    }
  
    getImageAddedObservable() {
      return this.imageAddedSubject.asObservable();
    }
  
    getImageRemovedObservable() {
      return this.imageRemovedSubject.asObservable();
    }
  
    getNavBarEventsObservable() {
      return this.navBarEventSubject.asObservable();
    }
  
    getElementPropertySetObservable() {
      return this.setElementPropertiesSubject.asObservable();
    }
  
    getElementPropertyChangeObservable() {
      return this.propertiesUpdateSubject.asObservable();
    }
  
    getTemplateLayers(): Observable<any> {
      return of(data);
    }
  
    getModuleAssets() {
      // return of(data.Modules);
      return null;
    }
  
    notifyElementsChanged(elements: CanvasElement[]) {
      this.elementsChangedSubject.next(elements);
      this.setUnsavedChanges(true);
    }
  
    getElementsChangedObservable() {
      return this.elementsChangedSubject.asObservable();
    }
  
    getElementSelectedObservable() {
      return this.elementSelectedSubject.asObservable();
    }
  
    updateSelectedModules(modules: string[], moduleId: string): void {
      if (modules && modules.length === 0) {
        this.templateConfig.mappings = this.templateConfig.mappings.filter(
          mapping => mapping.zone_id !== this.selectedElement.section_id
        );
        return;
      }
  
      let mappingFound = false;
      if (this.templateConfig.mappings) {
        for (const item of this.templateConfig.mappings) {
          if (item.zone_id === this.selectedElement.section_id) {
            item.module_name = modules[0];
            item.module_id = moduleId;
            mappingFound = true;
            break;
          }
        }
      }
  
      if (!mappingFound  && this.selectedElement.section_id && moduleId) {
        this.templateConfig.mappings.push({
          zone_id: this.selectedElement.section_id,
          module_name: modules[0],
          module_id: moduleId,
          is_deleted:false,
          is_dirty:true
        });
      }else{
        console.log("Cannot be mapped as ids are missing")
      }
      this.setUnsavedChanges(true);
    }
  
    getSelectedModulesObservable(): Observable<string[]> {
      return this.selectedModulesSubject.asObservable();
    }
  
    set setTemplateConfig(value: TemplateConfig) {
      if (!Array.isArray(value.mappings)) {
        console.error('Invalid mappings format:', value.mappings);
        value.mappings = [];
      }
      this.templateConfig = value;
      this.setUnsavedChanges(false);
    }
  
    get getTemplateConfig(): TemplateConfig {
      return this.templateConfig;
    }
  
    doSave(
      masterTemplateConfig: any,
      actionType: 'save' | 'submit',
      comments?: string,
      status?: string,
      saveModuleConfig?: string
    ):unknown {
  
      let storedDetails = this.templateService.getStoredTemplateDetails();
      let finalData = this.getTemplateConfig;
      finalData.method_code = '2';
      finalData.job_code = storedDetails.jobCode;
      finalData.user_id = storedDetails.userId;
      finalData.template_id = storedDetails.templateId;
      finalData.design_name = storedDetails.templateName;
      finalData.status = status || storedDetails.templateStatus;
      if (actionType === 'submit') {
        finalData.comments = comments || '';
      } else {
        delete finalData.comments;
      }
      finalData.canvas_configuration = this.templateConfig.canvas_configuration;
      if (saveModuleConfig) {
        finalData.module_configuration = this.templateConfig.module_configuration;
      } else {
        finalData.module_configuration = [];
      }
      this.setTemplateConfig = finalData;
      this.setUnsavedChanges(false);
  
      console.log("--------------------------------");
      console.log(finalData)
  
      return new Promise((resolve,reject)=>{
        this.templateService.updateTemplate(finalData).subscribe(
          response => {
            console.log('Template saved successfully:', response);
            this.setUnsavedChanges(false);
            
            // TODO: Enable Submit only after savin all the changes.
            this.templateService.getTemplateById(this.templateConfig.template_id).subscribe({
              next: (templateData) => {
                console.log('', templateData);
              },
              error: (error) => {
                console.error('Error getting template details:', error);
              }
            });
            resolve(response);
          },
          error => {
            console.error('Error saving template:', error);
            reject(error);
          }
        );
      })
    }
  
    notifyElementMove(direction: number): void {
      this.elementMoveSubject.next(direction);
    }
  
    getElementMoveObservable() {
      return this.elementMoveSubject.asObservable();
    }
    setUnsavedChanges(changes: boolean) {
      this.unsavedChangesSource.next(changes);
    }
    hasCustomisableSection(): boolean {
      return this.templateConfig.canvas_configuration.some(config => config.customizable);
    }
    setComments(comments: string) {
      this.commentsSubject.next(comments);
    }
  
    getComments(): string {
      return this.commentsSubject.value;
    }
  
    setModuleConfiguration(module_config: IElementConfig) {
  
     // if(!this.templateConfig.module_configuration){
    this.templateConfig.module_configuration = [];
     // }
     this.templateConfig.module_configuration.push(module_config);
    }
  
    setCanvasConfiguration(elementConfig: ElementConfig[]) {
      let exportData: CanvasElementConfig[] = elementConfig.map(element => {
  
        const elementObject:CanvasElementConfig = {
          type: element.type,
          section_id: element.section_id,
          x: element.x,
          y: element.y,
          text: element.text,
          element_name: element.element_name,
          element_url: element.element_url,
          width: element.width,
          height: element.height,
          customizable: element.customizable ?? false,
          page_number: element.page_number !== undefined ? element.page_number : 0,
          color: element.color,
          fontFamily: element.fontFamily,
          fontSize: element.fontSize,
          backgroundColor: element.backgroundColor,
          is_deleted:element.is_deleted,
          is_dirty:element.is_dirty
          }
  
        if(element.id){
          elementObject.id = element.id;
        }
        return elementObject;
      });
  
      this.templateConfig.canvas_configuration = exportData;
  
  }
}
