export default class EditorUtil {
  static generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}


export const PopupLabels = {
  confirmSubmit: {
    title: 'Submit template',
    label: 'Comments',
    placeholder: 'Comments',
    cancelText: 'Cancel',
    yesText: 'Yes'
  },
  submissionSuccess: {
    title: 'Template submitted successfully',
    okText: 'Ok'
  },
  approveSuccess: {
    title: 'Template approved successfully',
    message: 'Template approved successfully.',
    okText: 'Close'
  },
  confirmReject: {
    title: 'Reject template',
    label: 'Comments',
    placeholder: 'Enter rejection reason',
    cancelText: 'Cancel',
    rejectText: 'Reject'
  },
  rejectSuccess: {
    title: 'Template rejected successfully',
    message: 'The template has been rejected.',
    okText: 'Ok'
  },
  confirmContinue: {
    title: 'Approve template',
    message: 'Do you want to approve this template?',
    approveText: 'Approve',
    cancelText: 'Cancel'
  },
  confirmCancel: { 
    title: 'Save changes?',
    message: 'Do you want to save changes before exit?',
    noText: 'No',
    yesText: 'Yes',
  },
  saveAsDraft: {
    title: 'Save as Draft',
    name: 'Name',
    comments: 'Comment (optional)',
    saveText: 'Save',
    cancelText: 'Cancel',
  },
  instructionalText: {
    AE: `To perform download, add to collection, email to customer, and print to customer go to my saved items`,
    Customer: `To perform download, go to my saved items.`
  },
  confirmFinish:{
    title: 'Finish personalization',
    label: 'Draft resource name',
    placeholder: 'Enter resource name',
    FinsihText: 'Finish',
    cancelText: 'Cancel',
  },
  finishSuccess: {
    title: 'Successfully personalized',
    resourceNameLabel: 'Resource name',  
    message: 'You have successfully personalized your resource.',
    goToSavedItemsButton: 'Go to my saved items',
    countdownLabel: 's', 
    createNewButton: 'Create new personalization'
  },
  OnCustomizableImageClick: {
    title:'Select template image',
    selectText: 'Select',
    cancelText: 'Cancel'
  }
};


export const PersonalizedResourceLabels = {
  notificationBar: {
    AE: `To perform download, add to collection, email to customer, and print to customer go to saved items`,
   Customer: `To perform download, go to my saved items`
 }
}