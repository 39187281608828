<div class="search-container">
  <input
    type="text"
    [(ngModel)]="searchText"
    [placeholder]="placeholder"
    class="search-bar" />
  <button
    class="search-icon"
    (click)="onSearch()"
    (keydown.enter)="onSearch()"
    (keydown.space)="onSearch()"
    tabindex="0">
    <i class="bi bi-search"></i>
  </button>
</div>
