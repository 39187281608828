import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable, switchMap } from 'rxjs';
import { EUserRole, IUserRole } from '../models/auth.model';
import { endpoints } from '@app/shared/constants/auth.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userRole: string | null = null;
  private tokenSubject = new BehaviorSubject<string | null>(null);

  constructor(private http: HttpClient) {}
  
  setUserRole(role: string) {
    this.userRole = role;
    localStorage.setItem('userRole', role);
  }

  getUserRole(): string | null {
    return this.userRole || localStorage.getItem('userRole');
  }

  isTemplateAuthor(): boolean {
    return this.getUserRole() === EUserRole.author;
  }

  isTemplateApprover(): boolean {
    return this.getUserRole() === EUserRole.approver;
  }

  isCustomer(): boolean {
    return this.getUserRole() === EUserRole.customer; 
  }

  isAccountExecutive(): boolean {
    return this.getUserRole() === EUserRole.account_executive;
  }

  getAWSUrl(): string {
    let url = environment.apiGatewayUrl;
    const idp = localStorage.getItem('idp');
    if (idp && idp === 'SF') {
     url = environment.apiProxyApi; 
    }
    return url;
  }

  getSalesForceAccessToken(): Observable<any> {
    // const idp = localStorage.getItem('idp');
    // let auth = localStorage.getItem('idp_access_token');
    // if (idp && idp === 'SF') {
    //  auth = localStorage.getItem('authToken'); 
    // }
    console.log('Auth service');
    return this.http.get(`${this.getAWSUrl()}${endpoints.getSalesforceToken}`).pipe(
      switchMap((res: any) => {
        if (res.statusCode === 200) {
          const { access_token, instance_url } = res.body;
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('instance_url', instance_url);
          this.tokenSubject.next(access_token);
        }
        return this.tokenSubject.asObservable();
      })
    );
  }

  getToken() {
    return this.tokenSubject.asObservable();
  }

  getNewToken(): Observable<string> {
    return this.getSalesForceAccessToken();
  }

  getUserRoleForTBT(userName: string): Observable<IUserRole> {
    const instanceUrl = localStorage.getItem('instance_url');
    // const hostName = 'https://msdlogin--hhusd4.sandbox.my.salesforce.com';
    const path = '/services/data/v58.0/query/';
    const query = `q=SELECT+MSD_CORE_User_Name__c,+MSD_CORE_User_Role__c+FROM+MSD_CORE_HEQ_TBT_App_User_Detail__c+WHERE+MSD_CORE_User_Name__c='${encodeURIComponent(userName)}'`;
    return this.http.get<IUserRole>(`${instanceUrl}${path}?${query}`);
  }
}
