import { endpoints, idp } from '../app/shared/constants/auth.constants';

const cognito_domain = idp.cognito_url.local;
const identity_provider = idp.identity_provider.dev;
const redirectUrl = idp.redirect_url.dev;
const clientId = idp.client_id.dev;
const clientSecret = idp.client_secret.dev;
const scope = idp.scope;

export const environment = {
  production: false,
  cognito_domain,
  cognitoUrl: `${cognito_domain}/oauth2/token`,
  clientId,
  clientSecret,
  redirectUri: `${redirectUrl}`,
  pingfedAuthUrl: `${cognito_domain}/oauth2/authorize?identity_provider=${identity_provider}&redirect_uri=${redirectUrl}&response_type=CODE&client_id=${clientId}&scope=${scope}`,
  redirectUriLogout: 'https://tbt-dev.healtheq.com/sign-out',
  salesforceAuthService:
    'https://msdlogin--hhusd4.sandbox.my.salesforce.com/services/oauth2/token',
  designsEndPoint: `/services/apexrest/${endpoints.designs}`,
  templatesEndPoint: `/services/apexrest/${endpoints.templates}`,
  apiGatewayUrl: 'https://yd0677gs8i-vpce-0ef70c6611c9b5190.execute-api.ap-southeast-1.amazonaws.com/dev/',
  apiProxyApi: 'https://api-test.merck.com/healtheq-template-builder/v1/',
  portalRedirectLink: 'https://msdlogin--hhusd4.sandbox.my.site.com/healtheq/',
 };
