import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DesignsService } from '../../../../dashboard/services/designs.service';
import { MatTableModule } from '@angular/material/table';
import { ConfigDialogComponent } from "../config-dialog/config-dialog.component";
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CanvasEditorService } from '../../../services/canvas-editor.service';
import { TemplateService } from 'src/app/shared/services/template.service';
import { IElementConfig } from '@app/features/template-builder-tool/models/config-mapping.model';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';


@Component({
  selector: 'app-config-list',
  standalone: true,
  imports: [MatTableModule, MatIconModule, ConfigDialogComponent, SpinnerComponent],
  templateUrl: './config-list.component.html',
  styleUrl: './config-list.component.scss'
})
export class ConfigListComponent {
  readonly dialog = inject(MatDialog);

  templateId: string | null = null;
  templateName: string | null = null;
  templateData: any;
  jobCode: string | null | undefined;
  moduleData: Array<IElementConfig> = [];
  configData: Array<IElementConfig> = [];
  displayedColumns: string[] = ['id', 'label', 'type', 'action'];

  constructor(private templateService: TemplateService, private router: Router, private route: ActivatedRoute, private designsService: DesignsService, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.getRouteParams();
  }

  getRouteParams() {
    this.route.paramMap.subscribe(params => {
      this.templateId = params.get('templateId');
      this.templateName = params.get('templateName');
      this.jobCode = params.get('jobCode');
      if (this.jobCode) {
        this.fetchModuleData(this.jobCode);
        this.fetchTemplateData(this.templateId as string);
      }
    });
  }

  fetchModuleData(jobCode: string) {
    this.spinnerService.show();
    this.designsService.getDesign(jobCode).subscribe(
      (designResponse) => {
        this.moduleData = designResponse.Modules;
        this.spinnerService.hide();
      },
      (error) => {
        console.error('Error fetching design data:', error);
        this.spinnerService.hide();
      }
    );
  }

  fetchTemplateData(templateId: string) {
    this.spinnerService.show();
    this.templateService.getTemplateById(templateId).subscribe(
      {
        next: (response) => {
          this.templateData = response;
          this.configData = response.module_configuration || [];
          console.log('Fetched Template Data:', this.templateData);
          this.spinnerService.hide();
        },
        error: (error) => {
          console.error('Error occurred during subscription:', error); // Logs in case of an error.
          this.spinnerService.hide();
        }
      }
    );
  }

  openConfigDialog(_element?: any) {
    const dialogRef = this.dialog.open(ConfigDialogComponent, {
      backdropClass:'custom-dialog',
      width: "1020px",
      data: { moduleData: this.moduleData, moduleConfig: this.configData, canvasConfiguration: this.templateData.canvas_configuration, element: _element, jobCode: this.templateData.job_code }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.fetchTemplateData(this.templateId as string);

    });
  }
  addConfig() {
    this.openConfigDialog();
  }

  editConfig(element: any) {
    this.openConfigDialog(element);
  }

  back() {
    this.router.navigate(['/template-builder', this.templateId, this.jobCode]);
  }
}
