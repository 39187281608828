<h5>Modules</h5>
@if (isEmptyModules()) {
  <div>
    No modules available. Get the modules from the module configuration.
  </div>
}

@if (hasValidModules()) {
  <div class="moduleDiv">
    @for (module of filteredModules; track module) {
      <div class="module-item">
        @if (module) {
          <div class="d-flex align-items-center module-header" (click)="module.expanded = !module.expanded">
            <!-- // TODO: remove the [checked] loop and update the functionality -->
            <input type="checkbox" [id]="module.name" [value]="module.id" [checked]="isSelectedModule(module.name)" 
            (change)="onModuleCheckboxChange(module)">
            <label [for]="module.name" class="ms-2">{{ module.name }}</label>
            <i class="bi" [ngClass]="module.expanded ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
          </div>
          <div class="mappings-section">
            @if (module.expanded) {
              <div class="asset-list">
                @for (asset of module.assets; track asset) {
                  <div class="asset-item" (click)="onAssetClick(asset)">
                    <img [src]="asset" alt="Module Asset" class="asset-img">
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
}


