<div class="footer">
    <!-- <div class="links">
    @for(link of links; track link;let i=$index){
        <a [href]="link.href">{{ link.text }}</a>
        @if (i < links.length - 1) {
            <span class="separator">|</span>
        }
    }
    </div> -->
    <div class="links">
        @for(link of links; track link;let i=$index){
            @if(link.type == 'cookieBtn'){
                <a (click)="customizeMySettings()"  href="javascript:void(0);"  >{{ link.text }}</a>
            }
            @else{
                <a href="javascript:void(0);" (click)="showPopup(link.href)">{{ link.text }}</a>
            }
            <!-- @if (i < links.length - 1) {
                <span class="separator">|</span>
            } -->
        }
        </div>
    <div class="images">
        <img class="left-image" src="merck-horizontal-grey.png" alt="merck-horizontal-grey">
        <div class="right-images">
                <img src="MSD_CORE_HEQ_Accessibility.svg" alt="MSD_CORE_HEQ_Accessibility"(click)="openLink(imgLink)"
                style="cursor: pointer;">
        </div>
    </div>
    <div class="copyright-container">
        <div class="copyright">
            Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.
        </div>
        <div class="text-container">
            <div class="text-left">
                This site is intended only for residents of the United States and its territories, including Puerto Rico. 
            </div>
            <!-- <div class="text-right">
                US-XXX-##### MM/YY
            </div> -->
        </div>
    </div>
</div>

<div class="popup-overlay" *ngIf="popupVisible">
    <div class="popup-content">
        <span class="close-icon" (click)="closePopup()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M5.69343 4.51946C5.30291 4.12893 4.66975 4.12893 4.27922 4.51946C3.8887 4.90998 3.8887 5.54314 4.27922 5.93367L10.5858 12.2403L4.27922 18.5469C3.8887 18.9374 3.8887 19.5705 4.27922 19.9611C4.66975 20.3516 5.30291 20.3516 5.69343 19.9611L12 13.6545L18.3066 19.9611C18.6971 20.3516 19.3303 20.3516 19.7208 19.9611C20.1114 19.5705 20.1114 18.9374 19.7208 18.5469L13.4142 12.2403L19.7208 5.93367C20.1114 5.54314 20.1114 4.90998 19.7208 4.51946C19.3303 4.12893 18.6971 4.12893 18.3066 4.51946L12 10.826L5.69343 4.51946Z" fill="#333333"/>
            </svg>
        </span>
        <span class="leave-text">You are about to leave TBT</span>
        <span class="subtext">This link will take you to a site outside Template Builder Tool</span>
        <div class="button-container">
            <button class="proceed-button" (click)="proceed()">Proceed</button>
            <button class="cancel-button" (click)="closePopup()">Cancel</button>
        </div>
    </div>
</div>