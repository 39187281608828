import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CanvasElement } from '../../models/canvas-element';
import { CanvasEditorService } from '../../services/canvas-editor.service';
import { FormsModule } from '@angular/forms';
import { MappingsComponent } from "../mappings/mappings.component";
import { TabItemComponent, TabsComponent } from '@app/shared/helpers';
import { ZAxisIcons } from '../../consts/z-axis-icons';

interface Image {
  url: string;
}

@Component({
  selector: 'app-properties',
  standalone: true,
  imports: [FormsModule, TabsComponent, TabItemComponent, MappingsComponent],
  templateUrl: './properties.component.html',
  styleUrl: './properties.component.scss'
})
export class PropertiesComponent implements OnInit, OnDestroy {
  currentElement: CanvasElement | null = null;
  private elementPropertiesSubscription!: Subscription;
  public elements: CanvasElement[] = [];
  public editedText: string = '';
  public fontFamilies: string[] = [];

  constructor(private canvasEditorService: CanvasEditorService) {}

  ngOnInit(): void {
    this.elementPropertiesSubscription = this.canvasEditorService
      .getElementPropertySetObservable()
      .subscribe(element => {
        if (element) {
          this.currentElement = element;
          this.editedText = element?.text || '';
        } else {
          this.currentElement = null;
          this.editedText = '';
        }
      });
      this.loadFontsFromServer();
  }

  
  loadFontsFromServer(): void {
    const fontUrls = [
      'https://fonts.googleapis.com/css?family=Cantarell:italic|Droid+Serif:bold',
      'https://fonts.googleapis.com/css?family=Cantarell:i|Droid+Serif:b',
      'https://fonts.googleapis.com/css?family=Cantarell:i|Droid+Serif:700'
    ];

    fontUrls.forEach(url => {
      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = url;
      document.head.appendChild(linkElement);
    });
    this.fontFamilies = ['Cantarell', 'Droid Serif'];
  }

  get width(): number | null {
    return this.currentElement ? this.currentElement.width : null;
  }

  set width(value: number | null) {
    if (this.currentElement && value !== null) {
      this.currentElement.width = value;
      this.updateElementDimensions();
    }
  }

  get height(): number | null {
    return this.currentElement ? this.currentElement.height : null;
  }

  set height(value: number | null) {
    if (this.currentElement && value !== null) {
      this.currentElement.height = value;
      this.updateElementDimensions();
    }
  }

  toggleStaticElement(): void {
    if (this.currentElement) {
      this.currentElement.customizable = !this.currentElement.customizable;
      this.currentElement.is_dirty = true;
      this.currentElement.is_deleted = false;
      this.canvasEditorService.updateElementDimensions(this.currentElement);
    }
  }

  ngOnDestroy(): void {
    this.elementPropertiesSubscription.unsubscribe();
  }
  
  EditTextElement() {
    if (this.currentElement?.type === 'text') {
      this.currentElement.text = this.editedText;
      this.updateElementDimensions();
    }
  }

  updateElementDimensions(): void {
    if (this.currentElement) {      
      this.canvasEditorService.updateElementDimensions(this.currentElement);
    }
  }
  removeBackgroundColor() {
    if (this.currentElement?.type === 'text') {
      this.currentElement.backgroundColor = null;
      this.updateElementDimensions();
    }
  }

  changeElementOrder(stepUp: number) {
    if (this.currentElement) {
      this.canvasEditorService.notifyElementMove(stepUp);
    }
  }  

  btnIconType(iconType: string) {
    switch (iconType) {
      case ZAxisIcons.Up:
        return '&uarr;'; // Up arrow
      case ZAxisIcons.Down:
        return '&darr;'; // Down arrow
      case ZAxisIcons.UpTop:
        return '&uarr;&uarr;'; // Double up arrow
      case ZAxisIcons.DownTop:
        return '&darr;&darr;'; // Double down arrow
      default:
        return '';
    }
  }
  moveLayer(direction: string): void {
    switch (direction) {
      case ZAxisIcons.Up:
        this.changeElementOrder(1);
        break;
      case ZAxisIcons.Down:
        this.changeElementOrder(-1);
        break;
      case ZAxisIcons.UpTop:
        this.changeElementOrder(2);
        break;
      case ZAxisIcons.DownTop:
        this.changeElementOrder(-2);
        break;
      default:
        break;
    }
  }
}
